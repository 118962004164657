<template>
	<div class="index" :style="{background: appear_style==0?'#fff':'#282828',	color: appear_style==0? '#000':'#fff'}">
		<div class="index_item" v-for="item in index_items" @click="goToAbout(item)">
			<div class="title">{{item.title}}</div>
			<div class="introduce">{{item.content}}</div>
		</div>
		<UserLogin @changeStyle="change_style"></UserLogin>
	</div>
</template>

<script>
import UserLogin from "@/components/UserLogin.vue";
import {getNotice} from "@/api/common_api";

export default {
components: {
  UserLogin
},
data() {
	return {
		index_items:[{title:"GPT",content:"AI助手",router: "/gpt"},{title: "TTS",content:"文字转语音",router: "/tts"},{title: "Midjourney",content:"AI绘图",router: "/mj"}],
		appear_style:0,
	}
},
mounted() {
	this.get_appear_style()
	getNotice(this)
},
methods:{
	get_appear_style(){
		if(localStorage.getItem("appear_style")==null){
			this.appear_style=0
		}else{
			this.appear_style=localStorage.getItem("appear_style")
		}
	},
	change_style(value){
		this.appear_style=value
	},
	goToAbout(item) {
		// this.$router.push(item.router);
		if(item.title!="Stable Diffusion"){
			this.$router.push(item.router);
		}else{
			this.$message({
				message: '该模块开发中！',
				type: 'warning'
			});
		}
	}	
}
}
</script>

<style>
.index{
	position: absolute;
	display: flex;
	justify-content: center;
	justify-items: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background: aliceblue;
	
	.index_item{
		width: 170px;
		height: 200px;
		margin-left: 10px;
		padding: 10px;
		align-content: center;
		border-radius: 15px;
		box-shadow: 0px 0px 10px 0px #737bb4 inset;
		cursor: pointer;
		transition: width 0.3s, height 0.3s, box-shadow 1s;
		margin-top: -150px;
		
		.title{
			width: 100%;
			text-align: center;
			font-size: 20px;
			font-weight: 900;
			margin-top: 40px;
		}
		.introduce{
			margin-top: 20px;
			width: 100%;
			text-align: center;
			font-size: 20px;
			font-weight: 500;
		}
	}
	.index_item:hover{
		width: 185px;
		height: 215px;
		box-shadow: 0px 0px 10px 0px aqua inset;
	}
}
</style>