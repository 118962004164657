<template>
	<div style="display: flex;justify-content: center;align-items: center;height: 500px;">
		<el-upload
			class="orgvideo"
			drag
			:action=uploadIp
			:on-success="handleUpload"
			multiple>
			<i class="el-icon-upload"></i>
			<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
			<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
		</el-upload>
	</div>
</template>

<script>
export default {
data() {
	return {
		uploadIp:this.config.ip+"/video/upload",
	}
},
mounted() {
	
},
methods:{
	handleUpload(response, file) {
		var that = this
		if(response.status==200){
			var project_name = response.res
			let reader=new FileReader();
			reader.readAsDataURL(file.raw)//读取，转换字符编码
			reader.onload=function(e){
				let val=e.target.result;//获取数据
				that.$router.push({
					name : "videoRedraw" , 
					params : {video_url: val,project_name: project_name}
				})
			}
		}
	},
}
}
</script>

<style>
</style>