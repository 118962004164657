<template>
	<div style="position:absolute;width: 100%;height: 100%;overflow: hidden;">
		<iframe src="/inpaint/index.html" id="inpaint" width="100%" height="100%"></iframe>
	</div>
</template>

<script>
export default {
	props: {
		img_info: Object
	},
  data() {
	return {
	  
	};
  },
  mounted() {
  	const iframe = document.getElementById('inpaint');
	iframe.onload = () => {
		iframe.contentWindow.postMessage({ type: "inpaint_img_info",content: this.img_info }, '*');
	};
  }
};
</script>

<style>
</style>