<template>
  <div class="tts" :style="{color: appear_style==0?'#000':'aliceblue',background:appear_style==0?'#fff':'#282828'}">
	  <div class="left_cont" :style="{backgroundColor: appear_style==0?'':''}">
		 <el-button class="new_chat" :disabled="!is_complete" @click="newChat">
			New Chat<i class="el-icon-edit-outline" style="font-weight: 900;float: right;margin-left: 5px;"></i>
		 </el-button>
		 <div class="mes_list">
			<div class="mes_item" 
				@mouseover="onListMouseOver(index)" 
				@mouseout="onListMouseOut"
				:disabled="!is_complete" 
				v-for="(item, index) in mes_list" 
				:key="index" 
				@click="clickChat(mes_list.length-index-1)" 
				:style="{color: appear_style==0?'#000':'#fff',boxShadow: mes_list.length-index-1==cur_mes_list? '1px 1px aqua,-0.2px -0.2px aqua,0.1px 0.1px aqua inset':'',pointerEvents:!is_complete?'none':'auto'}">
				<!-- <div class="mes_item_con" >{{mes_list[mes_list.length-index-1].title}}</div> -->
				<input class="mes_item_con" :id="'chat_input'+(mes_list.length-index-1)" :readonly="!(mes_chat_edit_index==index)" v-model="mes_list[mes_list.length-index-1].title" :style="{borderColor:mes_chat_edit_index==index?'rgb(118, 118, 118)':'transparent' ,color:mes_chat_edit_index==index?'aqua':'#fff',color:appear_style==0? (mes_chat_edit_index==index?'aqua': '#000'):(mes_chat_edit_index==index?'aqua': '#fff'),background:mes_chat_edit_index==index?'#000':'transparent'}"/>
				<div class="mes_item_edit"  v-show="index==on_list_mouseover_index"><i @click="editMesListTitle(mes_list.length-index-1)" class="el-icon-edit-outline" style="font-weight: 900;"></i></div>
				<div class="mes_item_del"  v-show="index==on_list_mouseover_index"><i @click="remove_mes_list" class="el-icon-delete" style="font-weight: 900;"></i></div>
			</div>
		 </div>
	  </div>
	  
	  <div class="left2_cont">
		  <div class="tts_fold_list" @click="fold_list"><i class="el-icon-s-fold" v-show="!is_fold_list"></i><i class="el-icon-s-unfold"  v-show="is_fold_list"></i></div>
		  <div class="country_list">
				  <div class="language_title" :style="{color: appear_style==0?'#000':'#fff'}">语言</div>
				  <el-select class="language_select" filterable  v-model="mes_list[cur_mes_list].cur_language" placeholder="请选择" @change="langOrSexChange">
			      <el-option
			        v-for="item in country_list"
			        :key="item"
			        :label="item"
			        :value="item">
			      </el-option>
			    </el-select>
				<div class="sex_title" :style="{color: appear_style==0?'#000':'#fff'}">性别</div>
				<el-select class="sex_select" v-model="mes_list[cur_mes_list].cur_sex" placeholder="请选择" @change="langOrSexChange">
				    <el-option
				      v-for="item in sex_list"
				      :key="item.value"
				      :label="item.label"
				      :value="item.value">
					</el-option>
				</el-select>
				<div class="voice_player_list" id="voicePlayerList">
					<div class="voice_player" v-for="(item,index) in local_and_short_name_list" :key="index" @click="clickVoicePlayer(item)" :style="{boxShadow:  appear_style==0? (mes_list[cur_mes_list].cur_model.shortName==item[1] ? '0.1px 0px 4px #369afd inset':''):(mes_list[cur_mes_list].cur_model.shortName==item[1] ? '0.1px 0px 4px #aaffff inset':'')}">
						<img class="voice_avatar" slot="reference" :src="item[2]?require('../assets/boy1.jpg'):require('../assets/girl.jpeg')"/>
						<div class="voice_name" :style="{color: appear_style==0?'#000':'#fff'}">{{item[0]}}</div>
					</div>
				</div>
				<div class="style_and_role_list">
					<div class="role_list" v-if="style_and_role_list['rolePlayList']!=null">
					  <div class="role_list_title" :style="{color: appear_style==0?'#000':'#fff'}">角色扮演</div>
					  <el-select class="role_select" v-model="mes_list[cur_mes_list].cur_model.role" placeholder="请选择" @change="styleOrRoleChange">
						  <el-option
							v-for="item in style_and_role_list['rolePlayList']"
							:key="item"
							:label="item"
							:value="item">
						</el-option>
					  </el-select>
					</div>
					  <div class="style_list" v-if="style_and_role_list['styleList']!=null">
						  <div class="style_list_title" :style="{color: appear_style==0?'#000':'#fff'}">说话风格</div>
						  <el-select class="style_select" v-model="mes_list[cur_mes_list].cur_model.style" placeholder="请选择"  @change="styleOrRoleChange">
						      <el-option
						        v-for="item in style_and_role_list['styleList']"
						        :key="item"
						        :label="item"
						        :value="item">
						  	</el-option>
						  </el-select>
					  </div>
				</div>
		  </div>
	  </div>
	  <div class="right_cont">
		<div class="generate_audio">
			<div class="audio_controls">
				<el-popover
				  trigger="hover"
				  v-model="trans_popover_vis">
					  <div style="text-align: right; margin: 0;display: flex;">
						<el-button type="primary" size="mini" plain round @click="getListVoiceUrl">转换全部</el-button>
					  </div>
					  <i :style="{color:appear_style==0?'#2c3e50':'aliceblue'}" class="el-icon-s-promotion audio_control_item create_audio" slot="reference" @click="getVoiceUrl" v-show="trans_complete"></i>
				</el-popover>
				<i :style="{color:appear_style==0?'#2c3e50':'aqua',border:appear_style==0? '2px solid #818181':'2px solid aqua'}" class="el-icon-loading audio_control_item create_audio trans_loading" v-show="!trans_complete"></i>
				<el-popover
				 :disabled="(mes_list[cur_mes_list].voice_url==''||mes_list[cur_mes_list].voice_url==null)"
				  trigger="hover">
					  <div style="text-align: right; margin: 0;display: flex;">
						<el-button type="primary" size="mini" plain round @click="playMesList">播放全部</el-button>
					  </div>
					  <i :style="{color:appear_style==0?'#2c3e50':'aliceblue'}" class="el-icon-video-play audio_control_item playAudio" slot="reference" v-show="!is_playing" @click="playAudio"></i>
				</el-popover>
				<i :style="{color:appear_style==0?'#2c3e50':'aqua',border:appear_style==0? '2px solid #818181':'2px solid aqua'}" class="el-icon-video-pause audio_control_item pauseAudio" v-show="is_playing" @click="pauseAudio"></i>
			   <mini-audio
					ref="audioplayer"
					:width="400"
				  :audio-source=cur_audio_url
				  style="display: inline-flex;margin: 0px;"
				  :style="{color:appear_style==0?'#2c3e50':'aliceblue'}"
				></mini-audio>
				<el-popover
				  trigger="hover"
				  v-model="volume_popover_vis">
					  <div style="display: flex;justify-content: center;margin-top: 10px;">
							<el-slider v-model="volume_value" vertical height="100px" :min="0" :max="1" :step="0.01" @input="changeVolume"></el-slider>
					  </div>
					  <i :style="{color:appear_style==0?'#2c3e50':'aliceblue',border:appear_style==0? '2px solid #818181':'2px solid #fff'}" class="el-icon-mic change_volume" slot="reference"></i>
				</el-popover>
				<el-popover
				  trigger="hover"
				  v-model="audio_rate_popover_vis">
					  <div style="display: flex;justify-content: center;margin-top: 10px;">
							 <el-slider v-model="audio_rate_value" vertical height="100px" :min="0.5" :max="4" :step="0.01" @input="changeRate"></el-slider>
					  </div>
					  <i :style="{color:appear_style==0?'#2c3e50':'aliceblue',border:appear_style==0? '2px solid #818181':'2px solid #fff'}" class="el-icon-odometer audio_rate" slot="reference"></i>
				</el-popover>
				<i :style="{color:appear_style==0?'#2c3e50':'aliceblue',border:appear_style==0? '2px solid #818181':'2px solid #fff'}" class="el-icon-bottom download_audio" @click="downLoad(cur_audio_url)"></i>
				<div class="save" :style="{color:appear_style==0?'#2c3e50':'aliceblue',border:appear_style==0? '2px solid #818181':'2px solid #fff'}" @click="save"><i class="el-icon-document save_icon" slot="reference"></i></div>
			</div>
		  </div>
		  <div class="voice_mes_bottom">
			<div class="voice_mes_cont">
			  <div class="voice_mes_item" v-for="(item,index) in mes_list[cur_mes_list].mes" :key="index" @click="click_line(item.line)" :style="{boxShadow: cur_mes_list_line==item.line ? '-2px -2px 10px -4px #000':'',color:appear_style==0?'#00aaff':'aqua'}">
				  <div class="voice_mes_item_line_num" :style="{color: appear_style==0?(item.voice_url!=''&&item.voice_url!=null)? '#00aaff':'#2c3e50' : (item.voice_url!=''&&item.voice_url!=null)? '#00ffff':'aliceblue'}">{{item.line}}</div>
				  <div class="voice_mes_item_role">
					  <div class="voice_mes_item_role_unit" v-if="item.localName!=''&&item.localName!=null">{{item.localName}}</div>
					  <div class="voice_mes_item_role_unit" v-if="item.role!=''&&item.role!=null">{{item.role}}</div>
					  <div class="voice_mes_item_role_unit"  v-if="item.style!=''&&item.style!=null">{{item.style}}</div>
				  </div>
				  <textarea class="voice_mes_item_text" :style="{color:appear_style==0?'#2c3e50':'aliceblue'}" :id="'textarea'+item.line"  v-model="item.text" @input="input_change" @keydown="handleKeyDown" placeholder="请输入文本……"></textarea>
			  </div>
			</div>
			<div class="drag_line">
				<div class="drag_line_left"></div>
				<div class="drag_line_right"></div>
			</div>
			<div class="right_cont_gpt">
				<ChatGpt class="chatgpt" :is_fold_list="true" :wide_mode = "3" :is_require_userLogin="false" ref="chatgpt" />
			</div>
			<UserLogin @change="handleLogStatusChange" @changeStyle="change_style"></UserLogin>
		</div>
	  </div>
  </div>
</template>

<script>
import {create_uuid,is_login_suc} from "@/api/gpt_api";
import {getCountryList,getLocalAndShortNameList,getStyleAndRoleList,getVoiceUrl,getListVoiceUrl,saveTtsList,getTtsList,delMesList} from "@/api/tts_api";
import $ from 'jquery';
import ChatGpt from "@/components/ChatGpt.vue";
import UserLogin from "@/components/UserLogin.vue";

export default {
	components: {
	  ChatGpt,
	  UserLogin
	},
	data() {
		return {
			country_list: [],
			local_and_short_name_list:[],
			style_and_role_list: {},
			is_complete:true,
			mes_list:[
				{
				mes_list_id: null,
				title:"未命名",
				cur_language:"Chinese (Mandarin, Simplified)",
				cur_sex:-1,
				cur_model:{localName:"",shortName:"",style:"",role:""},
				mes:[
					{line:1,shortName:"",localName:"",style:"",role:"",text:"",voice_url:""}
				],
				voice_url:""
			}
			],
			cur_mes_list:0,				//mes_list索引
			cur_mes_list_line:0,	//0为默认值，当该值是0的时候代表用户哪一行也没选
			cur_trans_mes_line: 0,  //当前正在转换的是哪一行，防止用户在转换的时候创建新行导致音频文件赋值到了其他行，当然删除行的时候还是有错误，暂时忽略
			on_list_mouseover_index:-1,
			sex_list: [{
			          value: -1,
			          label: '全部'
			        }, {
			          value: 0,
			          label: '女'
			        }, {
			          value: 1,
			          label: '男'
			}],
			cur_audio_url: require('../assets/null.wav'),
			trans_complete:true,
			trans_popover_vis:false,
			volume_popover_vis: false,
			audio_rate_popover_vis: false,
			is_playing:false,
			volume_value:1,
			audio_rate_value:1,
			mes_chat_edit_index:-1,
			appear_style:0,
			is_fold_list:false
		};
	},
	mounted() {
		this.init()
	},
	methods: {
		init(){
			this.addVoicePlayerListScrollLis();
			this.getCountryList();
			this.getLocalAndShortNameList();
			this.dragLine();
			this.getTtsList()
			this.getAppearSyle()
			setTimeout(()=>{
				try{
					this.$refs.chatgpt.scale(3);
					this.$refs.chatgpt.scroll_bottom()
				}catch(e){}
			},1000)
			setTimeout(()=>{
				try{
					this.$refs.chatgpt.scale(3);
					this.$refs.chatgpt.scroll_bottom()
				}catch(e){}
			},3000)
		},
		fold_list(){
			this.is_fold_list = !this.is_fold_list
			var left_cont = $('.tts');
			if(this.is_fold_list){
				left_cont[0].style.gridTemplateColumns = '0px 300px auto';
			}else{
				left_cont[0].style.gridTemplateColumns = '250px 300px auto';
			}
			this.dragline_adjust()
		},
		getAppearSyle(){
			if(localStorage.getItem("appear_style")==null){
				this.appear_style=0
			}else{
				this.appear_style=localStorage.getItem("appear_style")
			}
			this.$refs.chatgpt.change_style(this.appear_style);
			this.jsModifiCss()
		},
		change_style(value){
			this.appear_style=value
			this.$refs.chatgpt.change_style(this.appear_style);
			this.jsModifiCss()
		},
		jsModifiCss(){
			if(this.appear_style==0){
				$(".el-input__inner").attr("style", "color: #606266;box-shadow: 0.4px 0.2px #000, -0.4px -0.2px #000;");
				$("span[data-v-3a8dc9f9]:last-child").attr("style", "color: #606266;");
				$(".slider[data-v-3a8dc9f9]").attr("style", "background: #868686;");
				// $(".el-select-dropdown").attr("style", "background: aliceblue;");
				// $(".el-select-dropdown").css("background", "aliceblue");
				// $(".el-select-dropdown__item").css("color", "#606266");
				// $(".el-select-dropdown__item").css("font-weight", "700");
			
				
			}else{
				$(".el-input__inner").attr("style", "color: #fff;box-shadow: 0.4px 0.2px #fff, -0.4px -0.2px #000;");
				$("span[data-v-3a8dc9f9]:last-child").attr("style", "color: aliceblue;");
				$(".slider[data-v-3a8dc9f9]").attr("style", "background: #f8f7f7;");
				// $(".el-select-dropdown").attr("style", "background: #2c3e50;");
				// $(".el-select-dropdown").css("background", "#2c3e50");
				// $(".el-select-dropdown__item").attr("style", "color: aliceblue;font-weight: 700");
				// $(".el-select-dropdown__item").css("color", "aliceblue");
				// $(".el-select-dropdown__item").css("font-weight", "700");
				// $(".el-select-dropdown__item.hover").attr("style", "background-color:  rgba(170, 255, 127, 0.5);border-radius: 5px;");
			}
		},
		
		remove_mes_list(){
			var that=this
			is_login_suc(localStorage.getItem("user_token")).then(response => {
				if(response.status==200 && response.res){
					if(that.mes_list.length>1){
						delMesList(that.mes_list[that.mes_list.length-that.on_list_mouseover_index-1].mes_list_id).then(response => {
							if(response.status==200){
								console.log("后台移除成功")
								console.log(response)
								if(that.mes_list.length-that.on_list_mouseover_index-1 <= that.cur_mes_list && that.cur_mes_list!=0){
									that.cur_mes_list=that.cur_mes_list-1
								}
								that.mes_list.splice(that.mes_list.length-that.on_list_mouseover_index-1, 1);
							}		
						})
					}
				}else{
					this.$message({
						message: '您还未登陆，请扫码登录！',
						type: 'warning'
					});
				}
			})
		
		},
		save(){
			var that = this
			// save前遍历一下防止that.mes_list.mes_list_id为空
			is_login_suc(localStorage.getItem("user_token")).then(response => {
				if(response.status==200 && response.res){
					for (var mes_item of that.mes_list) {
						if(mes_item.mes_list_id==null||mes_item.mes_list_id==''){
							mes_item.mes_list_id = create_uuid()
						}
					}
					var exmple_mes_list = JSON.parse(JSON.stringify(that.mes_list))
					for (var exmple_mes_item of exmple_mes_list){
						exmple_mes_item.cur_model = JSON.stringify(exmple_mes_item.cur_model)
					}
					
					console.log(exmple_mes_list)
					console.log(that.mes_list)
					saveTtsList(exmple_mes_list,localStorage.getItem("user_token")).then(response => {
						console.log(response)
						if(response.status==200){
							this.$message({
								message: '保存成功！',
								type: 'success'
							});
						}else{
							this.$message({
								message: '保存失败！\n'+response.res,
								type: 'error'
							});
						}
					})
				}else{
					this.$message({
						message: '您还未登陆，请扫码登录！',
						type: 'warning'
					});
				}
			})
			
			
		},
		getTtsList(){
			var that = this
			getTtsList(localStorage.getItem("user_token")).then(response => {
				console.log(response)
				if(response.status==200){
					if(response.res.length>0){
						that.mes_list = response.res
						for (var mes_item of that.mes_list) {
							mes_item.cur_model = JSON.parse(mes_item.cur_model)
						}
						that.cur_mes_list = that.mes_list.length-1
					}
				}
			})
		},
		
		handleLogStatusChange(){
			console.log("触发")
			this.verify_is_logining()
		},
		verify_is_logining(){
			var that=this
			if(localStorage.getItem("user_token")!=null){
				is_login_suc(localStorage.getItem("user_token")).then(response => {
					if(response.status==200 && response.res){
						that.getTtsList()
						console.log('response.res')
						console.log(response.res)
					}else{
						that.login_suc=false;
					}
				})
			}
		},
		dragline_adjust(){
			var rightPane = $('.right_cont_gpt');
			var chatGpt = $('.chatgpt')
			
			if(Math.abs(chatGpt.width()-rightPane.width())>4){
				chatGpt.width(rightPane.width())
			}
		},
		dragLine(){
			$(document).ready(function () {
				// 获取相关元素
				var container = $('.voice_mes_bottom');
				var dragLine = $('.drag_line');
				var rightPane = $('.right_cont_gpt');
				var chatGpt = $('.chatgpt')
				var leftPane = $('.voice_mes_cont');
				// 拖拽线的初始位置和容器的初始宽度
				var dragStartX;
				var containerWidth;
				var leftPaneWidth;
				var rightPaneWidth;
			
				// 初始化
				chatGpt.width(rightPane.width());
			
				// 鼠标和触控开始事件处理函数
				function onStart(event) {
					if (event.cancelable) {
						event.preventDefault(); // 防止选中文本
					}
					dragStartX = (event.type === 'mousedown') ? event.clientX : event.touches[0].clientX; // 获取开始位置
			
					containerWidth = container.width(); // 容器的宽度
					leftPaneWidth = leftPane.width();  // 左侧容器的宽度
					rightPaneWidth = rightPane.width(); // 右侧容器的宽度
			
					// 鼠标和触控移动、松开事件监听
					$(document).on('mousemove touchmove', onMouseMove);
					$(document).on('mouseup touchend', onEnd);
				}
			
				// 鼠标和触控移动事件处理函数
				function onMouseMove(event) {
					var currentX = (event.type === 'mousemove') ? event.clientX : event.touches[0].clientX; // 获取当前移动位置
					var offsetX = currentX - dragStartX; // 计算移动距离
			
					var newLeftPaneWidth = leftPaneWidth + offsetX;
					var newRightPaneWidth = rightPaneWidth - offsetX;
			
					// 更新左侧容器和右侧容器的宽度
					leftPane.width(newLeftPaneWidth);
					rightPane.width(newRightPaneWidth);
					chatGpt.width(rightPane.width());
			
					// 确保容器宽度不超过容器的宽度
					if (newLeftPaneWidth < 0) {
						leftPane.width(0);
						rightPane.width(containerWidth);
					} else if (newRightPaneWidth < 0) {
						leftPane.width(containerWidth);
						rightPane.width(0);
					}
				}
			
				// 鼠标和触控松开事件处理函数
				function onEnd() {
					// 移除鼠标和触控移动、松开事件监听
					$(document).off('mousemove touchmove', onMouseMove);
					$(document).off('mouseup touchend', onEnd);
				}
			
				// 绑定鼠标和触控开始事件
				dragLine.on('mousedown touchstart', onStart);
			});
		},
		changeRate(value){
			const miniAudioComponent = this.$refs.audioplayer;
			miniAudioComponent.setRate(value);
		},
		changeVolume(value){
			const miniAudioComponent = this.$refs.audioplayer;
			miniAudioComponent.setVolume(value);
		},
		playMesList(){
			var that=this
			if(that.mes_list[that.cur_mes_list].voice_url!=null&&that.mes_list[that.cur_mes_list].voice_url!=''){
				that.cur_audio_url = that.mes_list[that.cur_mes_list].voice_url
				that.playAudio()
			}
		},
		playAudio(){
			this.is_playing=true
			const miniAudioComponent = this.$refs.audioplayer;
			miniAudioComponent.play();
			console.log(miniAudioComponent.volume)
			const checkPlayingInterval = setInterval(() => {
				// Check if the audio is still playing
				if (!miniAudioComponent.playing) {
					// Stop the interval and update is_playing
					clearInterval(checkPlayingInterval);
					this.is_playing = false;
				}
			}, 100);
		},
		pauseAudio(){
			this.is_playing=false
			const miniAudioComponent = this.$refs.audioplayer;
			miniAudioComponent.pause();
		},
		getListVoiceUrl(){
			var that=this
			is_login_suc(localStorage.getItem("user_token")).then(response => {
				if(response.status==200 && response.res){
					that.trans_popover_vis = false
					var voiceRoleList = []
					for (var mes of that.mes_list[that.cur_mes_list].mes) {
					  if (mes.text!=null&&mes.text!=''&&mes.shortName!=null&&mes.shortName!=''){
						  voiceRoleList.push({name:mes.shortName,type:mes.style,role:mes.role,text:mes.text})
					  }
					}
					if(voiceRoleList.length==0){
						  this.$message({
						  	message: '请输入文本内容!',
						  	type: 'warning'
						  });
					  }else{
						  console.log("开始列表转换")
						  console.log(voiceRoleList)
						  that.trans_complete = false
						  getListVoiceUrl(voiceRoleList, localStorage.getItem("user_token")).then(response => {
							if(response.status==200){
								that.trans_complete=true
								this.$message({
									message: '转换成功!',
									type: 'success'
								});
								
								that.cur_audio_url = response.res
								that.mes_list[that.cur_mes_list].voice_url = response.res
							}else{
								that.trans_complete=true
								this.$message({
									message: response.res,
									type: 'error'
								});
							}
						})
					}
				}
			})
		},
		getVoiceUrl(){
			var that = this
			is_login_suc(localStorage.getItem("user_token")).then(response => {
				if(response.status==200 && response.res){
					if(
						that.cur_mes_list_line!=0 &&
						that.mes_list[that.cur_mes_list].mes[that.cur_mes_list_line-1].text!=null &&
						that.mes_list[that.cur_mes_list].mes[that.cur_mes_list_line-1].text!='' &&
						that.mes_list[that.cur_mes_list].mes[that.cur_mes_list_line-1].shortName!=null &&
						that.mes_list[that.cur_mes_list].mes[that.cur_mes_list_line-1].shortName!=''
					){
						that.trans_complete = false
						that.cur_trans_mes_line = that.cur_mes_list_line
						try{
							getVoiceUrl(
								that.mes_list[that.cur_mes_list].mes[that.cur_mes_list_line-1].shortName,
								that.mes_list[that.cur_mes_list].mes[that.cur_mes_list_line-1].style,
								that.mes_list[that.cur_mes_list].mes[that.cur_mes_list_line-1].role,
								that.mes_list[that.cur_mes_list].mes[that.cur_mes_list_line-1].text,
								localStorage.getItem("user_token")
							).then(response => {
								if(response.status==200){
									that.trans_complete=true
									this.$message({
										message: '转换成功!',
										type: 'success'
									});
									that.cur_audio_url = response.res
									that.mes_list[that.cur_mes_list].mes[that.cur_trans_mes_line-1].voice_url = response.res
									
								}else{
									that.trans_complete=true
									this.$message({
										message: response.res,
										type: 'error'
									});
								}
							}).catch(error => {
								console.log(error)
								that.trans_complete=true
								this.$message({
									message: '转换失败，请重试!',
									type: 'error'
								});
							});
						}catch(e){
							that.trans_complete=true
							this.$message({
								message: '转换失败，请重试!',
								type: 'error'
							});
						}
						
					}else{
						this.$message({
							message: '请输入文本并选择模型',
							type: 'warning'
						});
					}
				}
				else{
					this.$message({
						message: '您还未登陆，请扫码登录！',
						type: 'warning'
					});
				}
			})
			
		},
		//如果cur_mes_list_line不为0，即当前行被选中的状态，这时候被选中行模型根据cur_model里的模型改变
		changeMesListLineModel(){
			if(this.cur_mes_list_line!=0){
				this.mes_list[this.cur_mes_list].mes[this.cur_mes_list_line-1].shortName=this.mes_list[this.cur_mes_list].cur_model.shortName
				this.mes_list[this.cur_mes_list].mes[this.cur_mes_list_line-1].localName=this.mes_list[this.cur_mes_list].cur_model.localName
				this.mes_list[this.cur_mes_list].mes[this.cur_mes_list_line-1].style=this.mes_list[this.cur_mes_list].cur_model.style
				this.mes_list[this.cur_mes_list].mes[this.cur_mes_list_line-1].role=this.mes_list[this.cur_mes_list].cur_model.role
			}
		},
		clickVoicePlayer(model){
			this.mes_list[this.cur_mes_list].cur_model.localName=model[0]
			this.mes_list[this.cur_mes_list].cur_model.shortName=model[1]
			this.mes_list[this.cur_mes_list].cur_model.style=null
			this.mes_list[this.cur_mes_list].cur_model.role=null
			this.getStyleAndRoleList()
			this.changeMesListLineModel()
		},
		langOrSexChange(){
			this.getLocalAndShortNameList();
		},
		styleOrRoleChange(){
			this.changeMesListLineModel()
		},
		getCountryList(){
			var that = this
			getCountryList().then(response => {
				if(response.status==200){
					that.country_list = response.res
				}
			})
		},
		getLocalAndShortNameList(){
			var that = this
			getLocalAndShortNameList(that.mes_list[that.cur_mes_list].cur_language,that.mes_list[that.cur_mes_list].cur_sex).then(response => {
				if(response.status==200){
					that.local_and_short_name_list = response.res
					that.mes_list[that.cur_mes_list].cur_model.localName = that.local_and_short_name_list[0][0]
					that.mes_list[that.cur_mes_list].cur_model.shortName = that.local_and_short_name_list[0][1]
					that.getStyleAndRoleList()
					that.changeMesListLineModel()
				}
			})
		},
		getStyleAndRoleList(){
			var that = this
			getStyleAndRoleList(that.mes_list[that.cur_mes_list].cur_model.shortName).then(response => {
				if(response.status==200){
					that.style_and_role_list = response.res
				}
			})
		},
		// 2版本只获取不修改
		getLocalAndShortNameList2(){
			var that = this
			getLocalAndShortNameList(that.mes_list[that.cur_mes_list].cur_language,that.mes_list[that.cur_mes_list].cur_sex).then(response => {
				if(response.status==200){
					that.local_and_short_name_list = response.res
					that.getStyleAndRoleList()
				}
			})
		},
		click_line(line_num){
			var that=this
			that.cur_mes_list_line = line_num
			if(
				that.mes_list[that.cur_mes_list].mes[that.cur_mes_list_line-1].voice_url!=null&&
				that.mes_list[that.cur_mes_list].mes[that.cur_mes_list_line-1].voice_url!=''){
					console.log("111")
					that.cur_audio_url = that.mes_list[that.cur_mes_list].mes[that.cur_mes_list_line-1].voice_url
			}
			
			//如果点击某一行时该行没有model设置就把cur_model里的设置给该行
			if( that.mes_list[that.cur_mes_list].mes[that.cur_mes_list_line-1].shortName==null||that.mes_list[that.cur_mes_list].mes[that.cur_mes_list_line-1].shortName==''){
				that.mes_list[that.cur_mes_list].mes[that.cur_mes_list_line-1].localName = that.mes_list[that.cur_mes_list].cur_model.localName
				that.mes_list[that.cur_mes_list].mes[that.cur_mes_list_line-1].shortName = that.mes_list[that.cur_mes_list].cur_model.shortName
				that.mes_list[that.cur_mes_list].mes[that.cur_mes_list_line-1].role = that.mes_list[that.cur_mes_list].cur_model.role
				that.mes_list[that.cur_mes_list].mes[that.cur_mes_list_line-1].style = that.mes_list[that.cur_mes_list].cur_model.style
			}
		},
		handleKeyDown(event) {
			var that=this
			// 如果按下 Shift + Enter，则换行
			if (event.key === "Enter" && event.shiftKey) {
				this.inputText += "\n";
			} 
			// 如果只按下 Enter，则执行新建mes逻辑
			// for循环将后边的元素向后推移
			else if (event.key === "Enter" && this.is_complete) {
				for (let i = that.cur_mes_list_line; i < that.mes_list[that.cur_mes_list].mes.length; i++) {
					that.mes_list[that.cur_mes_list].mes[i].line +=1
				}
				
				that.mes_list[that.cur_mes_list].mes.splice(that.cur_mes_list_line,0,{line:that.cur_mes_list_line+1,shortName: that.mes_list[that.cur_mes_list].cur_model.shortName,localName:that.mes_list[that.cur_mes_list].cur_model.localName,style:that.mes_list[that.cur_mes_list].cur_model.style,role:that.mes_list[that.cur_mes_list].cur_model.role,text:""})
				event.preventDefault(); // 阻止默认的 Enter 换行行为
				
				that.cur_mes_list_line+=1
				// 设置焦点到textarea
				setTimeout(()=>{
					var textareaElement = document.getElementById('textarea'+that.cur_mes_list_line);
					textareaElement.focus();
				},100)
			} 
			// 如果文本框里没有内容还按删除按钮就删除当前行
			
			else if (event.key === "Backspace"&&that.mes_list[that.cur_mes_list].mes[that.cur_mes_list_line-1].text.length==0&&that.cur_mes_list_line!=1){
				that.cur_mes_list_line-=1
				that.mes_list[that.cur_mes_list].mes.splice(that.cur_mes_list_line, 1)
				
				for (let i = that.cur_mes_list_line; i < that.mes_list[that.cur_mes_list].mes.length; i++) {
					that.mes_list[that.cur_mes_list].mes[i].line -=1
				}
				
				setTimeout(()=>{
					var textareaElement = document.getElementById('textarea'+that.cur_mes_list_line);
					textareaElement.focus();
				},100)
			}
		},
		input_change(){
			$("textarea").on("input", function() {
				this.style.height = "auto";
				this.style.height = (this.scrollHeight) + "px";
				
			});
		},
		downLoad(voiceUrl){
			var that=this
			const xhr = new XMLHttpRequest();
			xhr.open('GET', voiceUrl);
			xhr.responseType = 'blob';
			
			xhr.onload = function() {
			  if (xhr.status === 200) {
			    const blob = xhr.response;
			    const url = URL.createObjectURL(blob);
			
			    const a = document.createElement('a');
			    a.href = url;
			    a.download = that.mes_list[that.cur_mes_list].title+'.wav';
			    a.click();
			
			    URL.revokeObjectURL(url);
			  }
			};
			
			xhr.send();
		},
		newChat(){
			var that = this
			if(that.trans_complete){
				var mes_list_id = create_uuid();
				that.mes_list.push({
					title:"未命名",
					mes_list_id: mes_list_id,
					cur_language:that.mes_list[that.cur_mes_list].cur_language,
					cur_sex: that.mes_list[that.cur_mes_list].cur_sex,
					cur_model:{localName:"",shortName:"",style:"",role:""},
					mes:[{line:1,shortName:'',localName: '',style: '',role:'',text:""}]
				})
				this.cur_mes_list = this.mes_list.length-1
				this.getLocalAndShortNameList()
				this.getStyleAndRoleList()
			}
		},
		clickChat(index){
			var that=this
			if(that.trans_complete){
				that.cur_mes_list = index;
				that.cur_mes_list_line = 0;
				that.getLocalAndShortNameList2()
			}
		},
		onListMouseOver(index){
			this.on_list_mouseover_index = index
		},
		onListMouseOut(){
			this.on_list_mouseover_index = -1
		},
		editMesListTitle(id_suffix){
			var that=this
			that.mes_chat_input_read_only = false;
			that.mes_chat_edit_index = that.on_list_mouseover_index;
			var id = "chat_input"+id_suffix
			var inputElement = document.getElementById(id);
			inputElement.focus();
			
			// 定义失去焦点事件处理函数
			function handleBlur() {
				// 在这里可以执行你想要的操作
				that.mes_chat_edit_index = -1;
				// 移除失去焦点事件监听器和按键事件监听器
				inputElement.removeEventListener('blur', handleBlur);
				inputElement.removeEventListener('keypress', handleKeyPress);
			}
		
			// 定义按键事件处理函数
			function handleKeyPress(event) {
				// 检查是否按下了 Enter 键 (keyCode 13)
				if (event.keyCode === 13) {
					// 在这里可以执行按下 Enter 键后的操作
					that.mes_chat_edit_index = -1;
					// 移除失去焦点事件监听器和按键事件监听器
					inputElement.removeEventListener('blur', handleBlur);
					inputElement.removeEventListener('keypress', handleKeyPress);
				}
			}
		
			// 添加失去焦点事件监听器和按键事件监听器
			inputElement.addEventListener('blur', handleBlur);
			inputElement.addEventListener('keypress', handleKeyPress);
		},
		
		addVoicePlayerListScrollLis(){
			var voicePlayerList = document.getElementById('voicePlayerList');
			voicePlayerList.addEventListener('wheel', function (event) {
				// 检查滚轮方向
				var delta = event.deltaY || event.detail || event.wheelDelta;
		
				// 根据滚轮方向调整水平滚动条位置
				if (delta < 0) {
					voicePlayerList.scrollLeft -= 50; // 向左滚动
				} else {
					voicePlayerList.scrollLeft += 50; // 向右滚动
				}
		
				// 阻止默认滚动行为
				event.preventDefault();
			});
		}
	}
};
</script>
<style>
.tts{
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: grid;
	grid-template-columns:250px 300px auto;
	position: absolute;

	.left_cont{
		height: 100%;
		margin: 0px 0px 10px 0px;
		padding: 10px 0px 10px 10px;
		display: grid;
		box-shadow: 0.2px 0px #ffffff;
		grid-template-rows:45px auto;
		overflow-x: scroll;
		
		.new_chat{
			width: 95%;
			height: 45px;
			background-color: #55557f;
			color: aliceblue;
			font-weight: 600;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 10px;
			margin-left: 3px;
		}
		
		.mes_list{
			position: relative;
			width: 100%;
			margin-top: 10px;
			overflow-y: scroll;
			margin-left: 3px;
			
			.mes_item{
				position: relative; 
				width: 95%;
				height: 45px;
				box-shadow: 2px 1px #535353,0.5px 0.5px #fff inset,-0.4px -0.4px #787878 inset;
				border-radius: 5px;
				margin-top: 8px;
				color: aliceblue;
				display: flex;
				align-items: center;
				padding-right: 5px;
				background-color: transparent;
				
				.mes_item_con{
					width: 100px;
					height: 20px;
					margin-left: 10px;
					display:inline;
					white-space: nowrap;
					overflow-x: hidden;
					text-overflow: ellipsis;/*溢出文本显示省略号*/
					background: transparent;
					color: #fff;
					padding: 3px;
					font-weight: 800;
					font-size: 14px;
				}
				.mes_item_edit{
					position: absolute;
					right: 35px;
					display:inline;
					width: 50px;
					height: 50px;
					/* background-color: antiquewhite; */
					justify-content: center;
					text-align: center;
					line-height: 50px;
					cursor: pointer;
				}
				.mes_item_del{
					position: absolute;
					right: 5px;
					display:inline;
					width: 50px;
					height: 50px;
					/* background-color: antiquewhite; */
					justify-content: center;
					text-align: center;
					line-height: 50px;
					cursor: pointer;
				}
			}
		}
	}


	
	.left2_cont{
		width: 300px;
		box-shadow: 0.2px 0px #ffffff;
		margin: 0px 10px 10px 0px;
		padding-left: 25px;
		position: relative;
		
		.tts_fold_list{
			width: 30px;
			height: 30px;
			font-size: 12px;
			margin-top: 15px;
			text-align: center;
			line-height: 30px;
			border-radius: 0px 3px 10px 0px;
			box-shadow: 1px 1px #959595;
			position: absolute;
			left: 0px;
			z-index: 101;
		}
		
		.country_list{
			padding: 20px;
			padding-top: 10px;
			
			.language_title{
				color: azure;
				padding-bottom: 10px;
				font-weight: 600;
				font-size: 15px;
			}
			
			.language_select{
				width: 100%;
			}
			
			.sex_title{
				color: azure;
				padding-bottom: 10px;
				padding-top: 10px;
				font-weight: 600;
				font-size: 15px;
			}
			
			.sex_select{
				width: 100%;
			}
			.voice_player_list{
				height: 110px;
				overflow-x: scroll;
				overflow-y: hidden;
				margin-top: 20px;
				white-space: nowrap; /* 防止换行 */
				padding-bottom: 30px;
				
				.voice_player{
					display: inline-block;
					padding: 10px;
					text-align: center;
					border-radius: 10px;
					cursor: pointer;
					
					.voice_avatar{
						width: 66px;
						height: 66px;
						border-radius: 50%;
					}
					
					.voice_name{
						color: aliceblue;
						box-shadow: 0.01px 0px #fff;
						border-radius: 5px;
						padding: 5px;
						margin-top: 3px;
					}
				}
			}
			.style_and_role_list{
				.role_list{
					padding-top: 15px;
					padding-bottom: 15px;
					
					.role_list_title{
						color: azure;
						padding-bottom: 10px;
						padding-top: 10px;
						font-weight: 600;
						font-size: 15px;
					}
					.role_select{
						display: flex;
					}
				}
				.style_list{
					.style_list_title{
						color: azure;
						padding-bottom: 10px;
						padding-top: 10px;
						font-weight: 600;
						font-size: 15px;
					}
					.style_select{
						display: flex;
					}
				}
			}
		}
	}
	
	.right_cont{
		/* color: aliceblue; */
		padding: 20px;
		overflow-y: hidden;
		margin-left: 40px;
		height: 100%;
		
		.generate_audio{
			
			.audio_controls{
				display: inline-block;
				/* background-image: linear-gradient(90deg,#737bb4,#82ddfd); */
				border-radius: 25px;
				padding-right: 15px;
				box-shadow: 5px 5px 10px -4px #737bb4,-5px -5px 10px -4px #000;
				
				.audio_control_item {
					cursor: pointer;
					margin-left: 10px;
					font-size: 20px;
					border: 2px solid transparent;
				}
				.create_audio{
					margin-left: 15px;
				}
				.create_audio:hover{
					color: aquamarine;
				}
				.trans_loading{
					color: aqua;
					font-weight: 500;
					border: 2px solid aqua;
					border-radius: 50%;
				}
				.playAudio{
					color: #fff;
					font-weight: 500;
					border-radius: 50%;
					border: 2px solid transparent;
				}
				.playAudio:hover{
					color: aquamarine;
				}
				.pauseAudio{
					color: aqua;
					font-weight: 500;
					border: 2px solid aqua;
					border-radius: 50%;
				}
				.change_volume{
					border: 2px solid #fff;
					border-radius: 50%;
					font-size: 12px;
					padding: 3px;
					cursor: pointer;
					margin-right: 13px;
				}
				.change_volume:hover{
					color: #ffff00;
					border: 2px solid #ffff00;
				}
				.audio_rate{
					border: 2px solid #fff;
					border-radius: 50%;
					font-size: 12px;
					padding: 3px;
					cursor: pointer;
					margin-right: 13px;
				}
				.audio_rate:hover{
					color: aquamarine;
					border: 2px solid aquamarine;
				}
				.download_audio{
					font-weight: 1000;
					display: inline;
					cursor: pointer;
					border-radius: 50%;
					font-size: 12px;
					padding: 2px;
					border: 2px solid #fff;
					margin-right: 13px;
				}
				.download_audio:hover{
					color: springgreen;
					border: 2px solid springgreen;
				}
				.save{
					display: inline;
					display: inline;
					cursor: pointer;
					border-radius: 50%;
					font-size: 12px;
					padding: 2px;
					border: 2px solid #fff;
					
					.save_icon{
						
					}
				}
				.save:hover{
					color: springgreen;
					border: 2px solid #0055ff;
				}
			}
		}
		.voice_mes_bottom{
			display: inline-flex;
			overflow-y: hidden; 
			margin-top: 20px;
			overflow-x: hidden;
			height: 100%;
			position: relative;
			width: 100%;
			
			.voice_mes_cont{
				max-height: 80vh; /* 设置最大高度为90%的视口高度 */
				overflow-y: auto; /* 当内容溢出时显示垂直滚动条 */
				flex-grow: 1;
				
				.voice_mes_item{
					/* box-shadow: 0.1px 0px #fff inset; */
					border-left: 1px solid #787878;
					border-radius: 10px;
					padding: 20px;
					display: flex;
					flex-direction: row; /* 默认是 row，可以省略这一行 */
					align-items: flex-start; /* 让子元素在交叉轴上顶端对齐 */
					margin-top:15px;
					
					.voice_mes_item_line_num{
						display: inline;
						padding: 5px;
						margin-top: 10px;
						font-weight: 700;
					}
					.voice_mes_item_role{
						display: inline-flex;
						flex-direction: column; /* 添加这一行 */
			
						.voice_mes_item_role_unit{
							max-width: 60px;
							font-size: 14px;
							text-align: center;
							overflow: hidden;
							text-overflow: ellipsis;/*溢出文本显示省略号*/
							font-weight: 700;
							margin-top: 10px;
							margin-left: 10px;
							padding: 5px;
							padding-left: 10px;
							padding-right: 10px;
							border-radius: 5px;
							box-shadow: 0.05px 0px #fff inset;
							/* color: #aaffff; */
						}
					}
					.voice_mes_item_text{
						margin-left: 20px;
						width: 80%;
						display: inline;
						height: 40px;
						border: 0px;
						font-size: 16px;
						color: aliceblue;
						background-color: transparent;
						resize:none;
						box-shadow: 0.1px 0px #fff inset;
						border-radius: 10px;
						padding: 10px;
						font-weight: 700;
						margin-top: 10px;
					}
				}
			}
			.drag_line {
				position: relative;
				width: 5px;
				min-width: 5px;
				cursor: ew-resize;
				background-color: transparent;
				margin: 10px;
				margin-top: 20px;
				border-left: 1px solid #566573;
				
				.drag_line_left{
					position: absolute;
					width: 1px;
					height: 60px;
					background-color: #566573;
					right: 9px;
					top: 40%;
				}
				.drag_line_right{
					position: absolute;
					width: 1px;
					height: 60px;
					background-color: #566573;
					left: 3px;
					top: 40%;
				}
			}
			.drag_line:hover {
			    background-color: transparent;
			}
			.right_cont_gpt{
				height: 100%;
				flex-grow: 2;
				
				.chatgpt{
					height: 90%;
					width: 100%;
					overflow: hidden;
					margin-right: 50px;
				}
			}
		}
	}
}
	.el-input--suffix .el-input__inner{
		background:transparent;
		border: 0px solid #fff;
		box-shadow: 0.4px 0.2px #fff, -0.4px -0.2px #000;
		/* color: #fff; */
		font-weight:700;
	}
	.el-select-dropdown{
		background-color:aliceblue;
	}
	.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
		background-color:  rgba(170, 255, 127, 0.5);
		border-radius: 5px;
	}
	.el-select-dropdown__item.selected{
		color: #55007f;
	}
	
	.el-select-dropdown__item{
		color: #808388;
		font-weight: 700;
	}
	
	.el-button {
	    margin-left: 0px;
	}
	.el-popover{
		min-width: 60px;
		background: transparent;
		border: 0px;
		padding: 2px;
	}
	.el-slider__button{
		width: 8px;
		height: 8px 
	}
	.el-slider.is-vertical .el-slider__runway{
		width: 4px;
	}
	.el-slider.is-vertical .el-slider__button-wrapper{
		left: -16px;
	}
	.el-slider__button{
		background-color: aqua;
	}
	.el-slider__bar{
		background-color: aqua;
		/* border: 2px solid #40e3fb; */
	}
	.el-slider.is-vertical .el-slider__bar{
		width: 0px;
	}
	.el-picker-panel, .el-popover, .el-select-dropdown, .el-table-filter, .el-time-panel{
		-webkit-box-shadow: 0 0px 0px 0 rgba(0,0,0,.1);
	}
	textarea:focus-visible {
		outline:-webkit-focus-ring-color auto 0px;
	}
	input {
	  outline: none; /* 移除默认边框 */
	  cursor: pointer;
	  /* border-color: transparent; */
	  /* border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133)); */
	}
	
	.hljs{
		border-radius: 8px 8px 8px 8px;
		padding: 8px;
		font-size: 16px;
	}
	.el-button{
		margin-left: 10px;
		color: #000;
	}
	
	
	.el-drawer__body{
		/* background-color: transparent; */
	}
	img{
		max-width: 100%;
	}
	
	
	textarea:focus-visible {
		outline:-webkit-focus-ring-color auto 0px;
	}
</style>
