<template>
  <div>
	<TTS />
  </div>
</template>

<script>
import TTS from "@/components/TTS.vue";
export default {
 components: {
   TTS
 },
	data() {
		return {};
	},
	mounted() {
	},
	methods: {
		
	}
};
</script>
