<template>
	<div>
		<div class="video_redraw_content">
			<div class="top">
				<video id="orgvideo" class="orgvideo" controls :src=video_url :style="{height:horilayout?'250px':'400px'}"></video>
				<video id="tmpvideo" controls :src=video_url v-show="false"></video>
				<!-- <img id="orgFrameImg" src="../assets/下载2.png" class="orgFrameImg" :style="{height:horilayout?'250px':'400px'}" @click="toggleFullScreen('org')"> -->
				<img id="orgFrameImg" class="orgFrameImg" :style="{height:horilayout?'250px':'400px'}" @click="toggleFullScreen('org')">
				<div class="config">
					<div style="max-width: 350px;">
						<div style="margin-bottom: 15px;">
							<div style="min-width: 120px;max-width: 200px;margin-right: 5px;font-size: 15px;display: inline-block;">一键重绘预设：</div>
							<el-cascader
								style="min-width: 120px;max-width: 200px;"
								v-model="presets"
								input-size=mini
								@change="presets_change"
								:options="presets_options"
								:props="{ expandTrigger: 'hover' }">
							</el-cascader>
						</div>
						
						<el-button type="warning" style="margin-right: 25px;max-width: 200px;min-width: 100px;margin-left: 0px" @click="creatRandom" plain>随机种子</el-button>
						<el-input v-model="seed" style="inline-block;min-width: 120px;max-width: 200px;" placeholder="请输入内容"></el-input>
					</div>
					
					
					
					<div style="display: grid; grid-template-columns: auto 1fr;justify-content: center;align-items: center;margin-top: 15px;">
						<div style="min-width: 120px;max-width: 200px;font-size: 15px;display: inline-flex;">重绘尺寸倍数：</div>
						<el-slider
							style="min-width: 120px;max-width: 200px;"
							:min=0.05
							:max=redraw_maxValue
							:step=0.05
							v-model="redraw_size_mult">
						</el-slider>
					</div>
					<div style="max-width: 400px;text-align: center;font-size: 14px;">从{{Math.floor(video_width*output_size)}}*{{Math.floor(video_height*output_size)}}到{{Math.floor(video_width*output_size*redraw_size_mult)}}*{{Math.floor(video_height*output_size*redraw_size_mult)}}</div>
					<div style="line-height: 32px;margin-top: 10px;">
						<el-popover
							placement="top-start"
							trigger="click">
							
							<el-select v-model="ade_model" size='small' placeholder="请选择" style="background: rgb(42, 42, 42,0.6);box-shadow: 1px 1px #818181;border-radius: 5px;color: aliceblue;">
								<el-option
									v-for="item in ade_model_list"
									:key="item.value"
									:label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
							<div slot="reference" style="width: 105px;font-weight: bold;font-size: 14.5px;display: inline-block;">
								<el-checkbox v-model="enable_ade" style="display: inline-block;"></el-checkbox>
								<div style="margin-left: 5px;display: inline-block;user-select: none;">人脸修复</div>
							</div>
						</el-popover>
						
						
						<el-popover
							width="450"
							placement="left"
							style="background: aliceblue;"
							trigger="click">
							<div style="background: rgb(42, 42, 42,0.7);padding: 20px;border-radius: 10px;color: #aaffff;font-weight: 800;">
								<el-radio-group v-model="control_net.ctr_mode" style="margin-bottom: 10px;">
									<el-radio :label="0" style="color: #dfdfdf;font-weight: 800;">均衡</el-radio>
									<el-radio :label="1" style="color: #dfdfdf;font-weight: 800;">更偏向提示词</el-radio>
									<el-radio :label="2" style="color: #dfdfdf;font-weight: 800;">更偏向ControlNet</el-radio>
								</el-radio-group>
								<el-checkbox v-model="control_net.ctr_perfect" style="width: 105px;font-weight: bold;color: #dfdfdf;font-weight: 800;padding: 5px 0px 5px 0px">完美像素模式</el-checkbox>
								<div style="display: inline-flex;width: 400px;">
									<div style="margin-right: 5px;line-height: 40px;width: 120px;">预处理分辨率：</div>
									<el-slider
										style="width: 280px;"
										:min=64
										:max=2048
										:step=1
										v-model="control_net.ctr_process"
										show-input>
									</el-slider>
								</div>
								<div style="display: inline-flex;width: 400px;">
									<div style="margin-right: 5px;line-height: 40px;width: 120px;">控制权重：</div>
									<el-slider
										style="width: 280px;"
										:min=0
										:max=2
										:step=0.05
										v-model="control_net.ctr_weight"
										show-input>
									</el-slider>
								</div>
								<div style="display: grid;grid-template-columns:40% 60%;line-height: 40px;">
									<div style="overflow: hidden;">预处理器和模型：</div>
									<el-cascader
										v-model="ctr_module"
										:options="ctr_module_list"
										:props="{ expandTrigger: 'hover' }"></el-cascader>
									</div>
							</div>
							<div slot="reference" style="width: 105px;font-weight: bold;font-size: 14.5px;display: inline-block;">
								<el-checkbox slot="reference" v-model="control_net.ctr_enabled" style="display: inline-block;"></el-checkbox><div style="margin-left: 5px;display: inline-block;user-select: none;">ControlNet</div>
							</div>
						</el-popover>
						
						<el-popover
							placement="top-start"
							trigger="click">
							<div style="background: rgb(42, 42, 42,0.7);padding: 20px;border-radius: 10px;color: #aaffff;font-weight: 800;padding-bottom: 50px;">
								<div style="max-width: 500px;display: inline-flex;align-items: center;">
									<div style="font-size: 15px;display: inline-flex;font-weight: bold;min-width: 90px;max-width: 340px;margin-right: 5px;font-size: 15px;">当前模型：</div>
									<el-cascader
										style="min-width: 120px;max-width: 340px;display: inline-block;margin-right: 10px;"
										v-model="model"
										:options="model_options"
										:props="{ expandTrigger: 'hover' }">
									</el-cascader>
								</div>
													
								<div style="display: grid; grid-template-columns: auto 1fr;justify-content: center;align-items: center;margin-top: 15px">
									<div style="max-width: 120px;font-size: 15px;display: inline-flex;font-weight: bold;margin-right: 5px;">反向提示词：</div>
									<el-input
										type="textarea"
										autosize
										placeholder="请输入内容"
										v-model="negative_prompt">
									</el-input>
								</div>
								<div style="display: grid; grid-template-columns: auto 1fr;margin-top: 15px;justify-content: center;align-items: center;">
									<div style="max-width: 120px;color: aliceblue;font-size: 15px;display: inline-flex;font-weight: bold;margin-right: 5px;">提示引导系数：</div>
									<el-slider
										style="max-width: 400px;"
										:min=1
										:max=30
										:step=0.2
										input-size=mini
										v-model="cfg_scale"
										show-input>
									</el-slider>
								</div>
								<div style="display: grid; grid-template-columns: auto 1fr;justify-content: center;align-items: center;margin-top: 15px">
									<div style="max-width: 120px;color: aliceblue;font-size: 15px;display: inline-flex;font-weight: bold;margin-right: 5px;">视频输出尺寸：</div>
									<el-slider
										style="width: 300px;"
										:min=0.05
										:max=out_maxValue
										:step=0.05
										input-size=mini
										v-model="output_size"
										show-input>
									</el-slider>
								</div>
								<div style="max-width: 400px;color: aliceblue;text-align: center;font-size: 14px;margin-bottom: 10px;">从{{video_width}}*{{video_height}}到{{Math.floor(video_width*output_size)}}*{{Math.floor(video_height*output_size)}}</div>
								<el-popover
									placement="top-start"
									width="200"
									trigger="hover"
									content="当修改输出视频尺寸后点击应用才能生效,修改其他设置不用点击应用按钮,建议使用默认设置">
									<el-button slot="reference" style="margin-left: 5px;float: right;" type="success" size="small" plain @click="extractProject" :disabled=lock>应用</el-button>
								</el-popover>
							</div>
							<el-button slot="reference" style="margin-left: 5px;float: right;" type="success" size="small" plain>更多</el-button>
						</el-popover>
						<el-button style="margin-left: 5px;float: right;" type="danger" size="small" plain @click="exit">退出</el-button>
					</div>
				</div>
				<div class="ver_result" v-if="!horilayout">
					<!-- <div class="ver_resFrameImg"><img id="resFrameImg" src="../assets/下载2.png" class="ver_res_img" @click="toggleFullScreen('res')"></div> -->
					<div class="ver_resFrameImg"><img id="resFrameImg" class="ver_res_img" :src="preview_image" @click="toggleFullScreen('res')"></div>
					<div class="ver_resFrameImg"><video id="resVideo1" class="ver_res_img" controls v-show="resVideo1Show"></video></div>
					<div class="ver_resFrameImg"><video id="resVideo2" class="ver_res_img" controls v-show="resVideo2Show"></video></div>
				</div>
			</div>
			
			<div class="timeline" id="timeline">
				<cueword ref="cuewordRef"></cueword>
				<div class="timeitem" v-for="(item, index) in 20" :key="index">
					<div style="width: 0px;float: left;">
						<div style="width: 2px;height: 10px;background: white;"></div>
						<div v-show='formatTime(org_video_dur/20 * (item-1))!=formatTime(org_video_dur/20 * (item-2))' :style="{'color': Math.floor(org_video_dur/20 * (item-1))<preview_images.length&&preview_images[Math.floor(org_video_dur/20* (item-1))]!=null?'aqua':''}">{{formatTime(org_video_dur/20 * (item-1))}}</div>
					</div>
					<div style="width: 0px;float: left;padding-left: 20%;">
						<div style="width: 1px;height: 5px;background: #bdbdbd;"></div>
					</div>
					<div style="width: 0px;float: left;padding-left: 20%;">
						<div style="width: 1px;height: 5px;background: #bdbdbd;"></div>
					</div>
					<div style="width: 0px;float: left;padding-left: 20%;">
						<div style="width: 1px;height: 5px;background: #bdbdbd;"></div>
					</div>
					<div style="width: 0px;float: left;padding-left: 20%;">
						<div style="width: 1px;height: 5px;background: #bdbdbd;"></div>
					</div>
					<!-- <img style="width: 100%;margin-top: 15px;" src="../assets/logo.png"> -->
				</div>
				<repaintrange ref="repaintRef" style="position: absolute;width: 100%;z-index: 20;"></repaintrange>
				<div id="frames" class="frames"></div>
				<div class="lineNeedle" id="lineNeedle"></div>
			</div>
			<div class="top_buts">
				<el-button type="primary" round @click="renderProject('preview_one')" :disabled=lock>预览当前</el-button>
				<el-button type="warning" round @click="renderProject('preview_all')" :disabled=lock>预览全部</el-button>
				<el-button type="success" round @click="renderProject('render_all')"  :disabled=lock>开始生成</el-button>
				<el-button type="success" round @click="renderProject('blend')"  :disabled=(lock||blend_lock) v-show="!blend_lock">稳定版</el-button>
				<el-button type="danger"  round @click="cancelProject" v-show="cancelShow">取消</el-button>
				<div class="project_status" style="width: 480px;position: absolute;left: 5px;color: aliceblue;justify-content: center;align-items: center;display: flex;">
					<div v-show="status.step" style="font-weight: bold;color: darkcyan;">当前状态：{{status.step}}</div>
					<div style="margin-left: 10px;">
						<div v-show="status.status=='queue'" style="color: #ffffff;font-weight: bold;">排队中：前面还有{{status.process}}人……</div>
						<div v-show="status.status=='render'" style="justify-content: center;align-items: center;display: flex;">
							<div style="float: left;color: #ffaa00;font-weight: bold;">处理中：</div>
							<el-progress style="width: 180px;float: left;" :text-inside="true" :stroke-width="20" :percentage="status.process" status="warning"></el-progress>
						</div>
						<div v-show="status.status=='complete'" style="color: springgreen;font-weight: bold;">处理完成 ! <i class="el-icon-circle-check" style="font-weight: bold;"></i></div>
					</div>
				</div>
			</div>
			<div class="hor_result" v-if="horilayout">
				<!-- <div class="hor_resFrameImg"><img id="resFrameImg" class="hor_res_img" src="../assets/下载.png" @click="toggleFullScreen('res')"></div> -->
				<div class="hor_resFrameImg"><img id="resFrameImg" class="hor_res_img" :src="preview_image" @click="toggleFullScreen('res')"></div>
				<div class="hor_resFrameImg"><video id="resVideo1" class="hor_res_img" controls v-show="resVideo1Show"></video></div>
				<div class="hor_resFrameImg"><video id="resVideo2" class="hor_res_img" controls v-show="resVideo2Show"></video></div>
			</div>
		</div>
	</div>
</template>


<!-- config = {
    "denoising_strength": [[0.555, 0.4], [0.556, 0.9], [0.8, 0.1]],
    "prompt": [[0, "(red hair:2.0)"], [0.4, "(white hair:2.0)"], [0.8, "(blue hair:2.0)"], [1, "(green hair:2.0)"]],
    "seed": 2352491646,
    "sd_model_checkpoint": "majicmixFantasy_v20.safetensors [bde6750eac]",
    "cfg_scale": 7,
    "height": 756,
    "width": 1344,
    "output_height": 756,
    "output_width": 1344,
    "Key_interval": 5,
	"enable_ade":false,
	"ade_model":""
} -->
<!-- {
value: 'anima\\meinamix_meinaV11.safetensors [54ef3e3610]',
label: 'meinamix_meinaV11'
}
{
value: 'real\\majicmixRealistic_v7.safetensors [7c819b6d13]',
label: '麦橘写实v7'
},{
value: 'real\\dreamshaper_8.safetensors [879db523c3]',
label: 'dreamshaper8'
},{
value: 'real\\realisticVisionV60B1_v60B1VAE.safetensors [fe7578cb5e]',
label: 'realisticV60B1'
} -->
		
<script>
import {render,cancel_project,get_model_list} from "@/api/video_redraw_api";
import {extractQuery,previewOneQuery,previewAllQuery,renderAllQuery,blendQuery,clearAllLisAndInterval} from "@/api/videoRedraw"
import repaintrange from "./RepaintRangeLine.vue";
import cueword from "./CueWord.vue";

export default {
  components: {
    repaintrange,
	cueword
  },
  data() {
    return {
		project_name:"",
		video_url: '',
		horilayout:true,
		org_video_dur:0,
		cfg_scale: 7,
		output_size: 1,
		redraw_size_mult: 1,
		seed:0,
		presets: [0,0],
		presets_options: [],
		video_width:0,
		model:'',
		model_options:[],
		video_height:0,
		preview_image: "",
		preview_images:[],
		fps:0,
		last_process: -1,
		resVideo1Show:false,
		resVideo2Show:false,
		cancelShow:false,
		lock:false,
		blend_lock:true,
		status:{
			step: "",
			status : "",
			process: 0
		},
		orgImgIsFullScreen: false,
		resImgIsFullScreen: false,
		enable_ade:false,
		ade_model: 'face_yolov8n.pt',
		ade_model_list: [{
			value: 'face_yolov8n.pt',
			label: 'face_yolov8n.pt'
			},{
				value: 'face_yolov8s.pt',
				label: 'face_yolov8s.pt'
			},{
				value: 'hand_yolov8n.pt',
				label: 'hand_yolov8n.pt'
			},{
				value: 'person_yolov8n-seg.pt',
				label: 'person_yolov8n-seg.pt'
			},{
				value: 'person_yolov8s-seg.pt',
				label: 'person_yolov8s-seg'
			},{
				value: 'mediapipe_face_full',
				label: 'mediapipe_face_full'
			},{
				value: 'mediapipe_face_short',
				label: 'mediapipe_face_short'
			},{
				value: 'mediapipe_face_mesh',
				label: 'mediapipe_face_mesh'
			}],
		control_net: {
			ctr_enabled:false,
			ctr_mode:2, //更偏向controlnet
			ctr_perfect:false,
			ctr_process:512,
			ctr_weight:1
		},
		ctr_module:"",
		ctr_module_list:[],
		negative_prompt: "BadDream,easynegative,verybadimagenegative_v1.3,ng_deepnegative_v1_75t,badhandv4,",
		currentTime:0,
		max_out_size: 1536,
		max_repaint_size: 2048
    };
  },
	computed: {
		out_maxValue() {
			return this.max_out_size / Math.max(this.video_width, this.video_height);
		},
		redraw_maxValue() {
			return this.max_repaint_size / Math.max(this.video_width*this.output_size, this.video_height*this.output_size);
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		init(){
			this.video_url = this.$route.params.video_url
			this.project_name = this.$route.params.project_name
			this.getTimeLine()
			this.getyModel()
		},
		get_presets(){
			this.presets_options = [{
				value: 0,
				label: '动漫风格',
				children: [{
				value: 0,
				label: '日系动漫1（推荐）'
				}]},{
				value: 1,
				label: '写实风格',
				children: [{
				value: 0,
				label: '亚洲写实1（推荐）'
				},{
				value: 1,
				label: '欧美写实1'
				},{
				value: 2,
				label: '其他风格1'
				}],
			}]
			this.presets_change()
		},
		presets_change(e){
			var that = this
			if(e==null){
				e=[0,0]
			}
			var emp =[[{sd_model_label:"anima_meinamix_meinaV11",
						control_net: {
							ctr_enabled:true,
							ctr_mode:2, //更偏向controlnet
							ctr_perfect:false,
							ctr_process:512,
							ctr_weight:1
						},
						ctr_module: ['Lineart','lineart_anime'],
						rectangles_y: 28
						}],[{sd_model_label:"real_majicmixRealistic_v7",
						control_net: {
							ctr_enabled:true,
							ctr_mode:2, //更偏向controlnet
							ctr_perfect:false,
							ctr_process:512,
							ctr_weight:1
						},
						ctr_module: ['Lineart','lineart_realistic'],
						rectangles_y: 28
						},{sd_model_label:"real_realisticVisionV60B1_v60B1VAE",
						control_net: {
							ctr_enabled:true,
							ctr_mode:2, //更偏向controlnet
							ctr_perfect:false,
							ctr_process:512,
							ctr_weight:1
						},
						ctr_module: ['Lineart','lineart_realistic'],
						rectangles_y: 28
						},{sd_model_label:"real_dreamshaper_8",
						control_net: {
							ctr_enabled:true,
							ctr_mode:2, //更偏向controlnet
							ctr_perfect:false,
							ctr_process:512,
							ctr_weight:1
						},
						ctr_module: ['Lineart','lineart_realistic'],
						rectangles_y: 28
						}]]
						
			that.control_net = emp[e[0]][e[1]].control_net
			if(Math.max(this.video_width, this.video_height)>that.max_out_size){
				that.redraw_size_mult = Number((1080/that.max_out_size-1080/that.max_out_size%0.05).toFixed(2))
			}else{
				that.redraw_size_mult = Number((1080/Math.max(this.video_width*that.output_size, this.video_height*that.output_size)-1080/Math.max(this.video_width*that.output_size, this.video_height*that.output_size)%0.05).toFixed(2))
			}
			var get_ctr_list = setInterval(() => {
				if(that.ctr_module_list.length!=0){
					for (let i = 0; i < that.ctr_module_list.length; i++) {
						if(that.ctr_module_list[i].value == emp[e[0]][e[1]].ctr_module[0]){
							var a = []
							a = emp[e[0]][e[1]].ctr_module
							a.push(that.ctr_module_list[i].children[0].children[1].value)
							that.ctr_module = a
							break
						}
					}
					clearInterval(get_ctr_list)
				}
			}, 300);
			
			var get_sdm_list = setInterval(() => {
				if(that.model_options!=null&&that.model_options.length!=0){
					for (let i = 0; i < that.model_options.length; i++) {
						if(that.model_options[i].label == emp[e[0]][e[1]].sd_model_label){
							var m = []
							m.push(that.model_options[i].value)
							that.model = m
							break
						}
					}
					clearInterval(get_sdm_list)
				}
			}, 300);
		
			this.$refs.repaintRef.update_repaint(emp[e[0]][e[1]].rectangles_y)
		},
		getyModel(){
			var that = this
			//get sd_model_list
			get_model_list("sd_model_list").then(response => {
				if(response.status==200){
					that.model_options = JSON.parse(response.res)
				}
			})
			//get control_model_list
			get_model_list("control_model_list").then(response => {
				if(response.status==200){
					var model_list = JSON.parse(response.res)
					for (let key in model_list.control_types) {
						var emp1 = {}
						emp1.value = key
						emp1.label = key
						emp1.children = []
						for (var i = 0; i < model_list.control_types[key]['module_list'].length; i++) {
							var emp2 = {}
							emp2.value = model_list.control_types[key]['module_list'][i]
							emp2.label = model_list.control_types[key]['module_list'][i]
							emp2.children = []
							for (var j = 0; j < model_list.control_types[key]['model_list'].length; j++) {
								var emp3 = {}
								emp3.value = model_list.control_types[key]['model_list'][j]
								emp3.label = model_list.control_types[key]['model_list'][j]
								emp2.children.push(emp3)
							}
							emp1.children.push(emp2)
						}
						if(key!="All"){
							that.ctr_module_list.push(emp1)
						}
					}
				}
			})
		},
		toggleFullScreen(type){
			if(type=='org'){
				var orgFrameImg = document.getElementById('orgFrameImg');
				var orgStyle;
				if (!this.orgImgIsFullScreen) {
					this.orgImgIsFullScreen = true;
					orgStyle=orgFrameImg.style
					orgFrameImg.style.position="absolute";
					orgFrameImg.style.height="90%";
					orgFrameImg.style.top = '5px';
					orgFrameImg.style.zIndex = 100000;
				}else{
					this.orgImgIsFullScreen = false;
					orgFrameImg.style = orgStyle
					orgFrameImg.style.height = this.horilayout?'250px':'400px'
				}
			}else if(type=='res'){
				var resFrameImg = document.getElementById('resFrameImg');
				var resStyle;
				if (!this.resImgIsFullScreen) {
					this.resImgIsFullScreen = true;
					resStyle=resFrameImg.style;
					resFrameImg.style.position="absolute";
					resFrameImg.style.height="90%";
					resFrameImg.style.top = '5px';
					resFrameImg.style.zIndex = 100000;
					resFrameImg.style.maxHeight = '1600px'
				}else{
					this.resImgIsFullScreen = false;
					resFrameImg.style = resStyle
				}
			}
		},
		cancelProject(){
			var that = this
			cancel_project(this.project_name).then(response => {
				if(response.status==200){
					that.cancelShow = false
				}
			})
		},
		extractProject(){
			var that = this
			var config = {
				"project_name": that.project_name, 
				"step": "extract",
				"output_height": Math.floor(that.video_height*that.output_size), 
				"output_width": Math.floor(that.video_width*that.output_size)
			}
			if(Math.max(this.video_width, this.video_height) > this.max_out_size){
				config["output_height"] = Math.floor(that.video_height* this.max_out_size/Math.max(this.video_width, this.video_height))
				config["output_width"] = Math.floor(that.video_width* this.max_out_size/Math.max(this.video_width, this.video_height))
			}
			
			console.log(config)
			render(JSON.stringify(config))
			extractQuery(that)
		},
		renderProject(type){
			const cuewordInstance = this.$refs.cuewordRef;
			const repaintInstance = this.$refs.repaintRef;
			var denoising_strength=[];
			var prompt=[];
			for (var i = 0; i < repaintInstance.rectangles.length; i++) {
				var x = repaintInstance.rectangles[i].x/repaintInstance.parent_width;
				var canvas = document.getElementById('repaintRange');
				var y = (1-((repaintInstance.rectangles[i].y-(canvas.height-repaintInstance.effect_height)/2)/repaintInstance.effect_height)).toFixed(2)
				denoising_strength.push([x, y]);
			}
			for (var j = 0; j < cuewordInstance.cur_words.length; j++) {
				var right = cuewordInstance.cur_words[j].right/100;
				var text = cuewordInstance.cur_words[j].text;
				prompt.push([right, text]);
			}
	
			var that = this
			const video = document.getElementById('orgvideo');
			var config = { 
				"project_name": that.project_name, 
				"step": type,
				"current_seconds": Math.floor(video.currentTime),
				"denoising_strength": denoising_strength,
				"prompt": prompt,
				"seed":that.seed,
				"sd_model_checkpoint":that.model[0],
				"cfg_scale":that.cfg_scale,
				"height":Math.floor(that.video_height*that.output_size*that.redraw_size_mult), 
				"width":Math.floor(that.video_width*that.output_size*that.redraw_size_mult),
				"output_height": Math.floor(that.video_height*that.output_size),
				"output_width": Math.floor(that.video_width*that.output_size),
				"enable_ade": that.enable_ade,
				"ade_model": that.ade_model,
				"ctr_enabled": that.control_net.ctr_enabled,
				"ctr_mode": that.control_net.ctr_mode,
				"ctr_perfect": that.control_net.ctr_perfect,
				"ctr_process": that.control_net.ctr_process,
				"ctr_module": that.ctr_module[1]==null?"":that.ctr_module[1],
				"ctr_model": that.ctr_module[2]==null?"":that.ctr_module[2],
				"ctr_weight": that.control_net.ctr_weight,
				"negative_prompt": that.negative_prompt
			}
			console.log(config)
			render(JSON.stringify(config))
			if(type == "preview_one"){
				that.currentTime = Math.floor(video.currentTime)
				previewOneQuery(that)
			}else if(type == "preview_all"){
				previewAllQuery(that)
			}else if(type == "render_all"){
				renderAllQuery(that)
			}else if(type == "blend"){
				blendQuery(that)
			}
		},
		exit(){
			clearAllLisAndInterval(this)
			this.$router.push({
				name : "index" 
			})
		},
		// 预览全部后点击或拖动时间线后预览图片也发生变化
		orgToPre(){
			var that = this
			const video = document.getElementById('orgvideo');
			var currentTime = Math.floor(video.currentTime);
			if(currentTime<that.preview_images.length){
				that.preview_image = that.preview_images[currentTime]
			}else{
				that.preview_image = ""
			}
		},
		creatRandom(){
			this.seed = Math.floor(Math.random() * 10000000000);
		},
		formatTime(totalSeconds) {
			const minutes = Math.floor(totalSeconds / 60);
			const seconds = totalSeconds % 60;
			
			return `${minutes.toString().padStart(2, "0")}:${Math.floor(seconds)
				.toString()
				.padStart(2, "0")}`;
		},
		
		lisClickTimeLine(){
			var that = this
			const timeline = document.getElementById('timeline');
			const lineNeedle = document.getElementById('lineNeedle');
			const video = document.getElementById('orgvideo');
			const tmpvideo = document.getElementById('tmpvideo');
			
			// 点击时间线
			timeline.addEventListener('click', function(event) {
				lineNeedle.style.left = event.clientX + 'px';
				video.currentTime = that.org_video_dur*(event.clientX/timeline.clientWidth)
			});
			
			// 滑动时间线
			timeline.addEventListener('mousedown', function() {
				document.addEventListener("mousemove", onMouseMove);
				document.addEventListener("mouseup", onMouseUp);
			})
			function onMouseMove(e) {
				e.preventDefault(); // 阻止默认行为，避免选中文本
				lineNeedle.style.left = e.clientX + 'px';
				video.currentTime = that.org_video_dur*(e.clientX/timeline.clientWidth)
			}
			function onMouseUp() {
				document.removeEventListener("mousemove", onMouseMove);
				document.removeEventListener("mouseup", onMouseUp);
			}
			
			// 每秒要渲染的原图
			video.addEventListener('timeupdate', function() {
				lineNeedle.style.left = timeline.clientWidth*(video.currentTime/that.org_video_dur) + 'px'
				tmpvideo.currentTime = Math.floor(video.currentTime)				
			});
			
			tmpvideo.addEventListener('seeked', function(){
				const canvas = document.createElement('canvas');
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
				const context = canvas.getContext('2d');
				const img = document.getElementById('orgFrameImg');
				context.drawImage(tmpvideo, 0, 0, canvas.width, canvas.height);
				img.src = canvas.toDataURL('image/png');
				that.orgToPre()
			})
		},
		
		getTimeLine(){
			var that = this
			const video = document.getElementById('tmpvideo');
			
			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');
			const framesDiv = document.getElementById('frames');
			function createTimeImages() {
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
				context.drawImage(video, 0, 0, canvas.width, canvas.height);
				const img = new Image();
				img.src = canvas.toDataURL('image/png');
				img.style="width:100%"
				const div = document.createElement('div');
				div.style = 'width:5%;';
				div.style.display = 'inline-block';
				
				div.appendChild(img);
				framesDiv.appendChild(div);
				const frameRate = that.org_video_dur/20; 
				if(video.currentTime==0){
					// 第一帧原始图像
					const img = document.getElementById('orgFrameImg');
					context.drawImage(video, 0, 0, canvas.width, canvas.height);
					img.src = canvas.toDataURL('image/png');
				}
				if(video.currentTime < that.org_video_dur-2*frameRate){
					video.currentTime += frameRate
				}else{
					video.removeEventListener('seeked',createTimeImages);
					that.lisClickTimeLine()
				}
			}
			video.addEventListener('seeked', createTimeImages);
			
			function loadVideo() {
				const duration = video.duration;
				that.org_video_dur = duration;
				video.currentTime = 0;
				that.video_width = video.videoWidth;
				that.video_height = video.videoHeight;
				if(that.video_height/that.video_width>1){
					that.horilayout = false
				}else{
					that.horilayout = true
				}
				

				that.extractProject()
				that.get_presets()
				video.removeEventListener('loadedmetadata', loadVideo);
			}
			video.addEventListener('loadedmetadata',loadVideo);
		}
	}
};
</script>

<style scoped>
.video_redraw_content{
	width: 100%;
	margin-top: 10px;
	display: flexbox;
	color: #585858;
	
	.top{
		display: flex;
		flex-wrap: wrap;
		
		.orgvideo{
			margin-left: 5px;
			border-radius: 10px;
		}
		
		.orgFrameImg{
			border-radius: 10px;
			margin-left: 5px;
		}
		
		.config{
			max-width: 520px;
			margin-left: 20px;
			justify-content: center;
			margin-top: 10px;
			font-weight: bolder;
		}
		
		.ver_result{
			display: inline-flex;
			max-height: 400px;
			flex-grow: 1;
			width: 300px;
			.ver_resFrameImg{
				flex: 1;
				border-radius: 10px;
				margin-left: 15px;
				
				.ver_res_img{
					max-width: 100%;
					max-height: 400px;
					border-radius: 10px;
				}
			}
		}
	}
	.timeline{
		width: 100%;
		padding-bottom:20px;
		background-color: #1a1a1a;
		
		.timeitem{
			width: 5%;
			float: left;
		}
		.frames{
			display: inline-block;
			user-select: none;
			width: 100%;
		}
		
		.lineNeedle{
			position: absolute;
			width: 1px;
			height: 85px;
			margin-top: -70px;
			background-color: aqua;
		}
	}
	.top_buts{
		width: 100%;
		padding: 15px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.hor_result{
		display: inline-flex;
		max-height: 400px;
		flex-grow: 1;
		width: 100%;
		.hor_resFrameImg{
			flex: 1;
			border-radius: 10px;
			margin-left: 15px;
			
			.hor_res_img{
				max-width: 100%;
				max-height: 300px;
				border-radius: 10px;
			}
		}
	}
}
</style>
<!-- 
realistic,multiple sexy girls,long hair,(skirt1.1),(pencil skirt:1.3),(jacket:1.2)big breast,smile,hold a microphone,stage hazy mist background,
(masterpiece:1.2),best quality,highly detailed,extremely_detailed_eyes_and_face,8k uhd,Cinematic Lighting,soft lighting,high quality,film grain,
FujifilmXT3, -->
