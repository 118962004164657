import $ from 'jquery';
import {gpt_ip} from './environment.js'
// 预处理
const ip = gpt_ip

export function render(config) {
	return $.ajax({
		url: ip+"/video/render",
		type: "POST",
		dataType: "json", // 期望的响应数据类型
		contentType: "application/json", // 设置请求的内容类型为 JSON
		data: config
	})
}

export function query_project(project_name) {
	return $.ajax({
		url: ip+"/video/query_project?project_name="+project_name,
		type: "GET"
	})
}	

export function get_video_info(project_name) {
	return $.ajax({
		url: ip+"/video/get_video_info?project_name="+project_name,
		type: "GET"
	})
}	

export function cancel_project(project_name) {
	return $.ajax({
		url: ip+"/video/cancel_project?project_name="+project_name,
		type: "GET"
	})
}			
//control_model_list和sd_model_list
export function get_model_list(type) {
	return $.ajax({
		url: ip+"/video/get_model_list?type="+type,
		type: "GET"
	})
}	

export function get_gpts_list(type,index) {
	return $.ajax({
		url: ip+"/gpt/getGptsList?type="+type+"&index="+index,
		type: "GET"
	})
}

