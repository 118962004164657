<template>
  <div>
	<timeline />
  </div>
</template>

<script>
import timeline from "@/components/TimeLine.vue";

export default {
 components: {
   timeline
 },
	data() {
		return {
			
		};
	},
	mounted() {
	
	},
	methods: {
		
	}
};
</script>
