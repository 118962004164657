<template>
	<div class="prompter">
		<div class="prompter_cont">
			<div class="body_descrip">
				<div class="body_descrip_title">主题描述</div>
				<textarea :style="{color: appear_style==0?'#000':'aliceblue',backgroundColor: appear_style==0?'#fff':'#282828'}" class="body_descrip_text" id="prompter_textarea"  @blur="handleBlur"></textarea>
				<div class="body_descrip_trans">百度翻译提供翻译</div>
				
			</div>
			<div class="other_params">
				<div class="other_params_title">其他描述</div>
				<div class="other_params_list">
					<div class="other_params_item" v-for="(item, index) in other_params">
						<div class="other_params_item_title" :style="{color: appear_style==0?'#686868':'#c5c5c5'}">
							{{item.title}}
						</div>
						<el-input class="other_params_item_select" style="width: 311px;" v-model="item.option_value" @change="handleDescripChange" :placeholder="item.placeholder"></el-input>
						<el-button v-if="!item.overlay" type="success" plain size="mini" icon="el-icon-plus" circle @click="overlayPrompt(other_params, item,index)"></el-button>
						<el-button v-if="item.overlay"  type="danger" plain size="mini" icon="el-icon-minus" circle @click="minusPrompt(other_params, index)"></el-button>
					</div>
				</div>
			</div>
			<div class="supple_descrip">
				<div class="supple_descrip_title">补充描述</div>
				<div class="supple_descrip_list">
					<div class="supple_descrip_item" v-for="(item, index) in supple_descriptions">
						<div class="supple_descrip_item_title" :style="{color: appear_style==0?'#686868':'#c5c5c5'}">
							{{item.title}}
						</div>
						 <el-select class="supple_descrip_item_select" v-model="item.option_value" @change="handleDescripChange" placeholder="请选择">
							<el-option
							  v-for="item2 in item.option_list"
							  :key="item2.value"
							  :label="item2.label"
							  :value="item2.value">
							</el-option>
						</el-select>
						<el-input-number style="z-index: 0;" v-if="item.option_num" v-model="item.option_num" controls-position="right" @change="handleDescripChange" :min="0" :max="100" :step="0.1"></el-input-number>
						<div v-if="!item.option_num" style="width: 80px;height: 10px;display: inline-block;"></div>
						<el-button v-if="!item.overlay" type="success" plain size="mini" icon="el-icon-plus" circle @click="overlayPrompt(supple_descriptions,item,index)"></el-button>
						<el-button v-if="item.overlay"  type="danger" plain size="mini" icon="el-icon-minus" circle @click="minusPrompt(supple_descriptions,index)"></el-button>
					</div>
				</div>
			</div>
			<div class="official_params">
				<div class="official_params_title">
					官方参数
					<div class="official_params_title_plus">
						<span>不懂的参数建议查看官方文档<a href="https://docs.midjourney.com/docs/parameter-list" target="_blank">https://docs.midjourney.com/docs/parameter-list</a></span>
					</div>
				</div>
				<div class="official_params_list">
					<div class="official_params_item" v-for="(item, index) in official_params">
						<div class="official_params_item_title" :style="{color: appear_style==0?'#686868':'#c5c5c5'}">
							{{item.title}}
						</div>
						<el-select v-if="item.type==0" class="official_params_item_select" v-model="item.option_value" @change="handleDescripChange" placeholder="请选择">
							<el-option
							  v-for="item2 in item.option_list"
							  :key="item2.value"
							  :label="item2.label"
							  :value="item2.value">
							</el-option>
						</el-select>
						<el-input class="official_params_item_select" style="width: 234px;"  v-else v-model="item.option_value" @change="handleDescripChange" :placeholder="item.placeholder"></el-input>
						<div class="official_params_item_remarks" :style="{color: appear_style==0?'#686868':'#c5c5c5'}">{{item.remarks}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="prompter_res" :style="{color: appear_style==0?'#000':'aliceblue',backgroundColor: appear_style==0?'#fff':'#282828'}">
			<div class="prompter_res_top">
				<div class="prompter_res_title">提示词生成结果</div>
				<el-button class="prompter_res_button" type="success" plain @click="submitPrompter">生成</el-button>
				<el-button class="prompter_res_button" type="primary" plain @click="copyPrompter">复制</el-button>
				<el-button class="prompter_res_button" type="info" plain @click="resetPrompter">重置</el-button>
			</div>
			<div class="prompter_res_bottom">
				<textarea class="prompter_res_text" id="prompter_res_textarea" :style="{color: appear_style==0?'#000':'aliceblue',backgroundColor: appear_style==0?'#fff':'#282828'}"></textarea>
			</div>
		</div>
	</div>
</template>

<script>
import $ from 'jquery';
import { supple_descriptions,official_params, other_params} from '../assets/mj_prompters.js';
import { transMes } from '../api/mj_api.js';
import { tranSuppleDescriptions, tranOfficialParams, tranPrefixSuppleDescriptions, tranOtherParams} from '../utils/mj_tools.js';
export default {
props: {
	appear_style: Number,
},
data() {
	return {
		supple_descriptions: supple_descriptions,
		official_params: official_params,
		trans_res:"",
		other_params: other_params,
		local_mj_prompt:{
			body_descrip:"",
			other_params:[],
			supple_descriptions:[],
			official_params:[],
			prompter_res:[]
		}
	}
},
mounted() {
	this.init()
},
methods: {
	init(){
		this.loadLocalMjPrompt()
	},
	saveLocalMjPrompt(){
		this.local_mj_prompt.body_descrip = $("#prompter_textarea").val()
		this.local_mj_prompt.other_params = this.other_params
		this.local_mj_prompt.supple_descriptions = this.supple_descriptions
		this.local_mj_prompt.official_params = this.official_params
		this.local_mj_prompt.prompter_res = $("#prompter_res_textarea").val()
		localStorage.setItem("local_mj_prompt",JSON.stringify(this.local_mj_prompt))
	},
	loadLocalMjPrompt(){
		if(localStorage.getItem("local_mj_prompt")){
			this.local_mj_prompt = JSON.parse(localStorage.getItem("local_mj_prompt"))
			$("#prompter_textarea").val(this.local_mj_prompt.body_descrip)
			this.other_params = this.local_mj_prompt.other_params
			this.supple_descriptions = this.local_mj_prompt.supple_descriptions
			this.official_params = this.local_mj_prompt.official_params
			$("#prompter_res_textarea").val(this.local_mj_prompt.prompter_res)
		}
	},
	overlayPrompt(list, item,index){
		var newItem = JSON.parse(JSON.stringify(item))
		newItem.overlay = true
		list.splice(index+1, 0, newItem);
	},
	minusPrompt(list,index){
		list.splice(index,1)
	},
	resetPrompter(){
		$("#prompter_textarea").val("")
		$("#prompter_res_textarea").val("")
		this.supple_descriptions.forEach(param => {
		  param.option_value = "";
		  if(param.option_num){
			  param.option_num = 1;
		  }
		});
		this.official_params.forEach(param => {
		  param.option_value = "";
		});
		this.other_params.forEach(param => {
		  param.option_value = "";
		})
	},
	submitPrompter(){
		this.$emit('submitPrompter', $("#prompter_res_textarea").val());
	},
	copyPrompter() {
		var res = $("#prompter_res_textarea").val()
		const input = document.createElement('input');
		input.setAttribute('value', res);
		document.body.appendChild(input);
		input.select();
		document.execCommand('copy');
		document.body.removeChild(input);
		this.$message({
			message: "复制成功！",
			type: 'success'
		});
		this.saveLocalMjPrompt()
	},
	handleDescripChange() {
		var prompter_res = this.trans_res;
		var prefix_supple_descrip_res = tranPrefixSuppleDescriptions(this.supple_descriptions)
		var other_params_res = tranOtherParams(this.other_params)
		var supple_descrip_res = tranSuppleDescriptions(this.supple_descriptions)
		var official_params_res = tranOfficialParams(this.official_params)
		prompter_res 
		if(supple_descrip_res!=""){
			if(prompter_res!=""){
				prompter_res+=","
			}
			prompter_res+=supple_descrip_res;
		}
		prompter_res += " "+official_params_res;
		prompter_res = other_params_res+prefix_supple_descrip_res+prompter_res
		const textarea = $("#prompter_res_textarea")
		textarea.val(prompter_res)
		this.$nextTick(() => {
		    textarea.scrollTop(textarea[0].scrollHeight);
		});
	},
	vueTransMes(userToken,mes,from,to){
		var that=this
		if(mes==""){
			return
		}
		transMes(userToken,mes,from,to).then(response => {
			if(response.status==200){
				that.trans_res = response.res
				that.handleDescripChange()
			}else{
				this.$message({
					message: response.res,
					type: 'warning'
				});
			}
		})
	},
	handleBlur(){
		this.vueTransMes(localStorage.getItem("user_token"),$("#prompter_textarea").val(),"zh","en")
	}
}
}
</script>

<style>
	.prompter{
		position: relative;
		
		.prompter_cont{
			padding: 20px 0px 0px 20px;
			height: 100%;
			overflow-y: scroll;
			
			.body_descrip{
				
				.body_descrip_title{
					font-size: 20px;
					font-weight: 900;
				}
				.body_descrip_text{
					width: 86%;
					max-width: 86%;
					min-width: 86%;
					min-height: 50px;
					font-size: 16px;
					background-color: transparent;
					font-weight: 600;
					margin: 10px;
					border-radius: 5px;
					padding: 10px;
				}
				.body_descrip_trans{
					padding: 0px 10px 5px 10px;
					font-size: 15px;
					font-weight: 550;
				}
			}
			
			.other_params{
				padding: 20px 0px 0px 0px;
				max-width: 95%;
				
				.other_params_title{
					font-size: 20px;
					font-weight: 900;
				}
				
				.other_params_list{
					display: inline-flex;
					flex-wrap: wrap;
					justify-content: space-between;
					padding: 10px 20px;
					
					.other_params_item{
						padding: 10px 5px 10px 5px;
						
						.other_params_item_title{
							font-size: 18px;
							font-weight: 700;
							padding: 10px 10px 10px 0px;
						}
						
						.other_params_item_select{
						
						}
					}
				}
				
			}
			.supple_descrip{
				max-width: 95%;
				
				.supple_descrip_title{
					font-size: 20px;
					font-weight: 900;
					margin-top: 10px;
				}
				.supple_descrip_list{
					display: inline-flex;
					flex-wrap: wrap;
					justify-content: space-between;
					padding: 10px 20px;
					
					.supple_descrip_item{
						padding: 10px 5px 10px 5px;
						
						.supple_descrip_item_title{
							font-size: 18px;
							font-weight: 700;
							padding: 10px 10px 10px 0px;
						}
						.supple_descrip_item_select{
							
						}
					}
				}
			}
			.official_params{
				max-width: 85%;
				padding-bottom: 300px;
				
				.official_params_title{
					font-size: 20px;
					font-weight: 900;
					margin-top: 10px;
				}
				.official_params_title_plus{
					display: inline-flex;
					font-size: 16px;
					font-weight: 700;
				}
				.official_params_list{
					display: inline-flex;
					flex-wrap: wrap;
					justify-content: space-between;
					padding: 10px 20px;
					
					.official_params_item{
						padding: 10px 5px 10px 5px;
						
						.official_params_item_title{
							font-size: 18px;
							font-weight: 700;
							padding: 10px 10px 10px 0px;
						}
						
						.official_params_item_select{
							font-size: 16px;
							font-weight: 800;
						}
						
						.official_params_item_remarks{
							max-width: 230px;
							font-size: 14px;
							font-weight: 500;
							display: flex;
							flex-wrap: wrap;
							padding: 5px;
						}
					}
				}
			}
		}
		
		.prompter_res{
			position: absolute;
			width: calc(100%-40px);
			bottom: 0px;
			padding:15px 0px 100px 20px;
			/* z-index: 100; */
			overflow: hidden;
			
			.prompter_res_top{
				width: 90%;
				
				.prompter_res_title{
					font-size: 20px;
					font-weight: 900;
					margin-top: 10px;
					display: inline-flex;
				}
				
				.prompter_res_button{
					float: right;
				}
			}
			
			.prompter_res_bottom{
				
				.prompter_res_text{
					width: 90%;
					min-width: 90%;
					max-width: 90%;
					min-height: 70px;
					font-size: 16px;
					background-color: transparent;
					font-weight: 600;
					margin: 10px;
					border-radius: 5px;
					padding: 10px;
				}
			}
		}
	}
	
	.el-input-number{
		width: 80px;
	}
	.el-input-number.is-controls-right .el-input__inner{
		padding: 8px;
		width: 40px;
	}
</style>