<template>
	<div style="display: flex;">
		<el-dialog
		  title="新建收藏夹"
		  :visible.sync="newFavoriteShow"
		  width="30%">
		  <div style="display: inline-flex;align-items: center;">
			  <div style="width: 150px;font-size: 16px;">收藏夹名称：</div>
			  <el-input v-model="folderName"  placeholder="请输入内容"></el-input>
		  </div>
		 <span slot="footer" class="dialog-footer" >
		   <el-button  @click="newFavoriteShow=false">取消</el-button>
		   <el-button type="primary"  @click="newFavorite">确定</el-button>
		 </span>
		</el-dialog>
	</div>
</template>

<script>
import {addCollectList} from "@/api/mj_api";

export default {
	data() {
		return {
			newFavoriteShow:false,
			folderName:""
		}
	},
	methods:{
		newFavorite(){
			if (this.folderName==""){
				this.$message({
					message: "收藏夹名不能为空！",
					type: 'warning'
				});
				return;
			}
			addCollectList(localStorage.getItem("user_token"),this.folderName).then(response => {
				if(response.status==200){
					this.$message({
						message: "创建成功！",
						type: 'success'
					});
					this.$emit('newFavoriteSuc');
					this.newFavoriteShow=false
					this.folderName=""
				}else{
					this.$message({
						message: response.res,
						type: 'warning'
					});
				}
			})
		}
	}
}
</script>

<style>
</style>