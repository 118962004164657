import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import index from "../views/index.vue";
import videoIndex from "../views/videoIndex.vue";
import videoRedraw from "../views/videoRedraw.vue";
import mj from "../views/mj.vue";
import chatGpt from "../views/chatGpt.vue";
import TTS from "../views/tTS.vue";
//定义路由表
const routes = [   
    {
        path:'/',
        component: index,
        name: 'index'
    },
    {
        path:'/videoRedraw',
        component: videoRedraw,
        name: 'videoRedraw'
    },
	{
	    path:'/videoIndex',
	    component: videoIndex,
	    name: 'videoIndex'
	},
	{
	    path:'/tts',
	    component: TTS,
	    name: 'TTS'
	},
    {
        path:'/gpt',
        component: chatGpt,
        name: 'gpt'
    },
	{
	    path:'/mj',
	    component: mj,
	    name: 'mj'
	}
]
 
//定义路由器
const router = new Router({
  routes,
  mode:'history'
})

//导出路由器
export default router;