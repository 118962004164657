<template>
	<div class="collect_mj_mes" :style="{color: appear_style==0?'#000':'aliceblue',backgroundColor: appear_style==0?'#fff':'#282828'}">
		<div class="gpt_cont_lef" :style="{backgroundColor: appear_style==0?'':''}">
			<el-button class="new_chat" @click="newFloder">
				New Chat<i class="el-icon-edit-outline" style="font-weight: 900;float: right;margin-left: 5px;"></i>
			</el-button>
			<div class="mes_list" v-if="mes_list.length>0">
				<div class="mes_item" 
					@mouseover="onListMouseOver(index)" 
					@mouseout="onListMouseOut"
					v-for="(item, index) in mes_list" 
					:key="index" 
					@click="clickChat(mes_list.length-index-1)" 
					:style="{color: appear_style==0?'#000':'#fff',boxShadow: mes_list.length-index-1==cur_mes? '1px 1px aqua,-0.2px -0.2px aqua,0.1px 0.1px aqua inset': appear_style == 0?'0.5px 0.5px #535353,0.5px 0.5px 0.5px 0.5px #bdbdbd inset': ''}">
					
					<input class="mes_item_con" maxlength="30" :id="'collect_input'+(mes_list.length-index-1)" :readonly="!(mes_chat_edit_index==index)" v-model="mes_list[mes_list.length-index-1].title" :style="{borderColor:mes_chat_edit_index==index?'rgb(118, 118, 118)':'transparent' ,color:appear_style==0? (mes_chat_edit_index==index?'aqua': '#000'):(mes_chat_edit_index==index?'aqua': '#fff'),background:mes_chat_edit_index==index?'#000':'transparent'}"/>
					<div class="mes_item_edit"  v-show="index==on_list_mouseover_index"><i @click="editMesListTitle(mes_list.length-index-1,  $event)" class="el-icon-edit-outline" style="font-weight: 900;"></i></div>
					
					<div class="mes_item_del"  v-show="index==on_list_mouseover_index"><i @click="remove_mes_list(mes_list[mes_list.length-index-1].id, $event)" class="el-icon-delete" style="font-weight: 900;" :style="{color:confirm_delete?'red':''}"></i></div>
				</div>
			</div>
		</div>
		
		<div class="cont_rig">
			<div class="gpt_fold_list" @click="fold_list"><i class="el-icon-s-fold" v-show="!re_is_fold_list"></i><i class="el-icon-s-unfold"  v-show="re_is_fold_list"></i></div>
			<div class="mes_cont">
				<div class="mj_img_top">
					<el-input class="mj_img_search_input" v-model="search_input"  @keydown.native="handleKeyDown" placeholder="根据备注信息搜索"></el-input>
					<el-button @click="searchImg" type="info" plain icon="el-icon-search" style="background-color: transparent;"></el-button>
					<el-popover
						placement="bottom"
						trigger="click">
						<div style="padding: 30px;border-radius: 10px;" :style="{color: appear_style==0?'#000':'aliceblue',backgroundColor: appear_style==0?'aliceblue':'#1f1f1f'}">
							<div style="display: flex;">
								<el-radio style="margin-top: 5px;margin-left: 0px;display: inline;" border size="medium" v-model="mj_collect_setting.search_range" @input="save_collect_setting" label="0">搜索当前收藏夹</el-radio>
								<el-radio style="margin-top: 5px;margin-left: 0px;display: inline;" border size="medium" v-model="mj_collect_setting.search_range" @input="save_collect_setting" label="1">搜索全部收藏夹</el-radio>
							</div>
							<div style="display: flex;padding: 5px;padding-top: 15px;">
								<el-checkbox v-model="mj_collect_setting.show_note" @change="handle_show_note_change">显示备注</el-checkbox>
								<!-- <el-checkbox v-model="mj_collect_setting.show_page" @change="handle_show_page_change">显示分页</el-checkbox> -->
							</div>
						</div>
						<el-button slot="reference" type="info" plain icon="el-icon-setting"  style="background-color: transparent;"></el-button>
					</el-popover>
					<el-button @click="refresh" type="info" plain icon="el-icon-refresh-right" style="background-color: transparent;"></el-button>
				</div>
				<div class="mj_img_list" ref="collectMesContainer" @scroll="handleScroll">
					<Waterfall ref="waterfall" :list="mj_img_list" :breakpoints={2000:{rowPerView:5},1600:{rowPerView:4},1200:{rowPerView:3},900:{rowPerView:2},600:{rowPerView:1}} backgroundColor="">
						<template #item="{ item, index }">
							<div class="card" @click="click_collect_img(item.imgUrls[0])" @mouseover="showImgOption(index)" @mouseleave="hideImgOption">
								<LazyImg class="card_img" :url="item.thumbnailUrls[0]" />
								<div v-show="collectVisibleIndex==(index)||is_mobile" style="position: absolute;bottom: 0px;width: 100%;">
									<EditCollectInfo :curCollectId="mes_list[cur_mes].id" :colIndex="index" :appear_style="appear_style" :mj_mes_item="item" @newFloder="newFloder" @editCollectSubmit="editCollectSubmit"></EditCollectInfo>
									<el-button size="mini" type="success" plain icon="el-icon-position" style="background-color: transparent;float: right;color: aliceblue;" @click="position_collect_img(item,$event)"></el-button>
								</div>
								<div style="width: 100%;display: flex;justify-content: center;">
									<div style="max-width: 150px; white-space: nowrap;overflow-x: hidden;text-overflow: ellipsis;text-align: center;line-height: 30px;align-self: center;color: aliceblue;" v-if="mj_collect_setting.show_note">{{item.notes}}</div>
								</div>
							</div>
						</template>
					</Waterfall>
				</div>
				<div class="paginationContain" >
					<div class="pagination">
						<el-pagination
							layout="prev, pager, next"
							:total="cur_total"
							:page-size="page_size"
							:current-page="cur_page_num"
							@current-change	= "cur_page_mode_change">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<PictureZoom ref="collectPictureZoom"></PictureZoom>
		<NewFavorites ref="newFavorites" @newFavoriteSuc="newFavoriteSuc"></NewFavorites>
	</div>
</template>

<script>
import $ from 'jquery';
import { LazyImg, Waterfall } from 'vue-waterfall-plugin'
import 'vue-waterfall-plugin/dist/style.css'
import PictureZoom from "@/components/PictureZoom.vue";
import EditCollectInfo from "@/components/EditCollectInfo.vue";
import NewFavorites from "@/components/NewFavorites.vue";
import {getCollectList, removeCollectList, updateCollectList, getCollectMesList} from "@/api/mj_api";

export default {
	components: { LazyImg, Waterfall, PictureZoom, EditCollectInfo, NewFavorites}, // 组件列表
	props: {
		appear_style: Number,
	},
	data() {
		return {
			is_mobile: this.isMobile(),
			mj_collect_setting:{
				search_range: "0",
				show_note: true
			},
			mes_list: [],
			mj_img_list: [],
			cur_mes:0,
			on_list_mouseover_index:-1,
			mes_chat_edit_index:-1,
			re_is_fold_list: true,
			search_input:"",
			collectVisibleIndex:null,
			confirm_delete: false,
			cur_page_num:1,
			page_size:14,
			cur_total:0,
			cur_load_mode:0, // 0：频道加载，1：搜索加载
			is_load_more:false,
		}
	},
	
	mounted() {
		this.init()
	},
	methods:{
		init(){
			this.fold_list_init()
			this.getCollectList()
			
			if(localStorage.getItem("mj_collect_setting")){
				this.mj_collect_setting = JSON.parse(localStorage.getItem("mj_collect_setting"))
			}
		},
		refresh(){
			var that=this
			that.getCollectMesList("",that.mes_list[that.cur_mes].id,1,this.page_size)
		},
		cur_page_mode_change(page_num){
			this.pageGetCollectMes(page_num)
		},
		handleScroll() {
			const container = this.$refs.collectMesContainer;
			if(container.clientHeight+container.scrollTop>=container.scrollHeight-20&&!this.is_load_more){
				console.log("划到地步了")
				this.is_load_more=true
				this.scrollGetMoreCollectMes()
			}
		},
		getCurCollectId() {
			var that=this
			return that.mes_list[that.cur_mes].id;
		},
		newFavoriteSuc(){
			this.getCollectList()
		},
		editCollectSubmit(col_index){
			var that=this
			that.hideWaterfallItemByDisplay(col_index)
			that.update_waterfall()
		},
		hideWaterfallItemByDisplay(index) {
			var elements = document.getElementsByClassName("waterfall-item");
			
			if (index >= 0 && index < elements.length) {
				elements[index].style.display = "none";
			} else {
				console.log("Index out of range.");
			}
		},
		clickChat(index){
			var that=this
			that.cur_mes = index
			that.getCollectMesList("",that.mes_list[that.cur_mes].id,1,this.page_size)
			that.cur_load_mode=0
		},
		scrollGetMoreCollectMes(){
			this.cur_page_num+=1
			console.log("cur_page_num")
			console.log(this.cur_page_num)
			if(this.cur_load_mode==0){
				this.getMoreCollectMes("", this.mes_list[this.cur_mes].id, this.cur_page_num,this.page_size)
			}else if(this.cur_load_mode==1){
				if(this.mj_collect_setting.search_range=='0'){
					this.getMoreCollectMes(this.search_input, this.mes_list[this.cur_mes].id, this.cur_page_num,this.page_size)
				}else if(this.mj_collect_setting.search_range=='1'){
					this.getMoreCollectMes(this.search_input, '', this.cur_page_num,this.page_size)
				}
			}
		},
		pageGetCollectMes(page_num){
			console.log("page_num")
			console.log(page_num)
			this.cur_page_num = page_num
			this.is_load_more=true
			if(this.cur_load_mode==0){
				this.getCollectMesList("", this.mes_list[this.cur_mes].id, page_num,this.page_size)
			}else if(this.cur_load_mode==1){
				if(this.mj_collect_setting.search_range=='0'){
					this.getCollectMesList(this.search_input, this.mes_list[this.cur_mes].id, page_num,this.page_size)
				}else if(this.mj_collect_setting.search_range=='1'){
					this.getCollectMesList(this.search_input, '', page_num,this.page_size)
				}
			}
		},
		getMoreCollectMes(notes, collectMjMesListId, pageNum, pageSize){
			var that=this
			getCollectMesList(localStorage.getItem("user_token"), notes, collectMjMesListId, pageNum, pageSize).then(response => {
				if(response.status==200){
					that.mj_img_list.push(...response.res.collectMjMesList)
					setTimeout(()=>{
						that.is_load_more=false
					},1000)
					console.log(that.mj_img_list)
				}
			})
		},
		getCollectMesList(notes, collectMjMesListId, pageNum, pageSize){
			var that=this
			that.mj_img_list = []
			that.cur_page_num = pageNum
			getCollectMesList(localStorage.getItem("user_token"),notes, collectMjMesListId, pageNum, pageSize).then(response => {
				if(response.status==200){
					setTimeout(()=>{
						that.is_load_more=false
					},1000)
					that.mj_img_list = response.res.collectMjMesList
					that.cur_total = response.res.total
					console.log(that.mj_img_list)
				}
			})
		},
		getCollectList(){
			var that=this
			getCollectList(localStorage.getItem("user_token")).then(response => {
				if(response.status==200){
					that.mes_list = response.res
					that.cur_mes = that.mes_list.length-1
					that.getCollectMesList("",that.mes_list[that.cur_mes].id,1,that.page_size)
				}else{
					this.$message({
						message: response.res,
						type: 'warning'
					});
				}
			})
		},
		updateCollectTitle(id, title){
			if (title==''||title==null){
				this.$message({
					message: "内容不能为空",
					type: 'warning'
				});
				return 
			}
			updateCollectList(id, title).then(response => {
				if(response.status!=200){
					this.$message({
						message: response.res,
						type: 'warning'
					});
				}
			})
		},
		remove_mes_list(id, event){
			var that=this
			if(event!=null){
				event.stopPropagation();
			}
			if (that.confirm_delete){
				removeCollectList(id).then(response => {
					if(response.status==200){
						console.log(that.cur_mes)
						console.log(that.mes_list.length-that.on_list_mouseover_index-1)
						//删除的是当前频道
						if(that.cur_mes==that.mes_list.length-that.on_list_mouseover_index-1){
							if(that.cur_mes>0){
								that.getCollectMesList("",that.mes_list[that.cur_mes-1].id,1,that.page_size)
							}else if(that.cur_mes==0){
								if (that.mes_list.length>1){
									that.getCollectMesList("",that.mes_list[1].id,1,that.page_size)
								}else{
									that.mj_img_list = []
								}
							}
						}
						that.cur_mes=that.cur_mes>0?that.cur_mes-1:that.cur_mes
						
						this.$message({
							message: "移除成功！",
							type: 'success'
						});
						that.mes_list.splice(that.mes_list.length-that.on_list_mouseover_index-1, 1);
						
					}else{
						this.$message({
							message: response.res,
							type: 'warning'
						});
					}
				})
				that.confirm_delete=false
			}else{
				that.confirm_delete=true
			}
		},
		searchImg(){
			var that=this
			console.log(this.$refs.waterfall)
			if(that.mj_collect_setting.search_range=='0'){
				that.getCollectMesList(this.search_input,that.mes_list[that.cur_mes].id,1,that.page_size)
			}else if(that.mj_collect_setting.search_range=='1'){
				that.getCollectMesList(this.search_input,'',1,that.page_size)
			}
			that.cur_load_mode=1
		},
		handleKeyDown(event) {
			if (event.key === "Enter" && event.shiftKey) {
			// 如果按下 Shift + Enter，则换行
				this.inputText += "\n";
			} else if (event.key === "Enter") {
			// 如果只按下 Enter，则执行提交逻辑
				event.preventDefault(); // 阻止默认的 Enter 换行行为
				this.searchImg()
			}
		},
		newFloder(){
			this.$refs.newFavorites.newFavoriteShow=true
		},
		position_collect_img(mj_mes, event){
			if(event!=null){
				event.stopPropagation();
			}
			this.$emit('collectPosition',mj_mes);
		},
		showImgOption(index) {
			this.collectVisibleIndex = index;
		},
		hideImgOption() {
			this.collectVisibleIndex = null;
		},
		handle_show_note_change(){
			this.update_waterfall()
			this.save_collect_setting()
		},
		// handle_show_page_change(){
		// 	this.save_collect_setting()
		// },
		update_waterfall(){
			this.$refs.waterfall.renderer()
		},
		save_collect_setting(){
			localStorage.setItem("mj_collect_setting", JSON.stringify(this.mj_collect_setting))
		},
		click_collect_img(img_url){
			this.$refs.collectPictureZoom.click_mj_img(img_url)
		},
		fold_list_init(){
			var left_cont = $('.collect_mj_mes');
			if(this.re_is_fold_list){
				left_cont[0].style.gridTemplateColumns = '0px auto';
			}else{
				left_cont[0].style.gridTemplateColumns = '250px auto';
			}
		},
		fold_list(){
			this.re_is_fold_list = !this.re_is_fold_list
			var left_cont = $('.collect_mj_mes');
			if(this.re_is_fold_list){
				left_cont[0].style.gridTemplateColumns = '0px auto';
			}else{
				left_cont[0].style.gridTemplateColumns = '250px auto';
			}
		},
		newChat(){
			
		},
		
		editMesListTitle(id_suffix, event){
			var that=this
			if(event!=null){
				event.stopPropagation();
			}
			
			that.mes_collect_input_read_only = false;
			that.mes_chat_edit_index = that.on_list_mouseover_index;
			var id = "collect_input"+id_suffix
			var inputElement = document.getElementById(id);
			inputElement.focus();
			
			function saveEditTitle(){
				var item = that.mes_list[that.mes_list.length-that.mes_chat_edit_index-1]
				that.updateCollectTitle(item.id, item.title)
			}
			
			// 定义失去焦点事件处理函数
			function handleBlur() {
				// 在这里可以执行你想要的操作
				saveEditTitle()
				that.mes_chat_edit_index = -1;
				// 移除失去焦点事件监听器和按键事件监听器
				inputElement.removeEventListener('blur', handleBlur);
				inputElement.removeEventListener('keypress', handleKeyPress);
			}
		
			// 定义按键事件处理函数
			function handleKeyPress(event) {
				// 检查是否按下了 Enter 键 (keyCode 13)
				if (event.keyCode === 13) {
					// 在这里可以执行按下 Enter 键后的操作
					saveEditTitle()
					that.mes_chat_edit_index = -1;
					// 移除失去焦点事件监听器和按键事件监听器
					inputElement.removeEventListener('blur', handleBlur);
					inputElement.removeEventListener('keypress', handleKeyPress);
				}
			}
		
			// 添加失去焦点事件监听器和按键事件监听器
			inputElement.addEventListener('blur', handleBlur);
			inputElement.addEventListener('keypress', handleKeyPress);
		},
		onListMouseOver(index){
			this.on_list_mouseover_index = index
			this.confirm_delete=false
		},
		onListMouseOut(){
			this.on_list_mouseover_index = -1
		},
	}
}
</script>

<style>
	.collect_mj_mes{
		width: 100%;
		height: 100%;
		overflow: hidden;
		display: grid;
		grid-template-rows:100%;
		color: #fff;
		
		.gpt_cont_lef{
			height: 100%;
			margin: 10px 0px 10px 0px;
			margin-left: 10px;
			display: grid;
			grid-template-rows:45px auto 100px;
			box-shadow: 0.4px 0px #7d7d7d;
			overflow-y: hidden;
			overflow-x: hidden;
			position: relative;
			
			.new_chat{
				width: 95%;
				height: 45px;
				background-color: #55557f;
				color: aliceblue;
				font-weight: 600;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 10px;
				margin-left: 3px;
			}
			
			.mes_list{
				position: relative;
				width: 98%;
				margin: 10px 6px 0px 3px;
				overflow-y: scroll;
				
				.mes_item{
					position: relative; 
					width: 95%;
					height: 45px;
					border-radius: 5px;
					margin-top: 8px;
					/* color: aliceblue; */
					display: flex;
					align-items: center;
					padding-right: 5px;
					background-color: transparent;
					
					.mes_item_con{
						width: 100px;
						margin-left: 10px;
						display:inline;
						white-space: nowrap;
						overflow-x: hidden;
						text-overflow: ellipsis;/*溢出文本显示省略号*/
					}
					.mes_item_edit{
						position: absolute;
						right: 35px;
						display:inline;
						width: 50px;
						height: 50px;
						/* background-color: antiquewhite; */
						justify-content: center;
						text-align: center;
						line-height: 50px;
						cursor: pointer;
					}
					.mes_item_del{
						position: absolute;
						right: 5px;
						display:inline;
						width: 50px;
						height: 50px;
						/* background-color: antiquewhite; */
						justify-content: center;
						text-align: center;
						line-height: 50px;
					}
				}
			}
		}
		
		.cont_rig{
			width: 100%;
			height: 100%;
			/* display: flex;
			justify-content: center; */
			overflow-y: hidden;
			scroll-behavior: smooth;
			display: grid;
			position: relative;
			grid-template-columns: 0% 100%;
			grid-template-areas: 'a b';
			
			.gpt_fold_list{
				width: 30px;
				height: 30px;
				font-size: 12px;
				margin-top: 15px;
				text-align: center;
				line-height: 30px;
				border-radius: 0px 3px 10px 0px;
				box-shadow: 1px 1px #959595;
				position: absolute;
				left: 0px;
				z-index: 101;
			}
			
			.mes_cont {
				grid-area: b;
				position: relative;
				width: 100%;
				height: 100%;
				overflow: hidden;
				
				.mj_img_top{
					width: 80%;
					padding: 10px;
					height: 60px;
					padding-left: 35px;
					align-content: center;
					
					.mj_img_search_input{
						width: 50%;
					}
				}
				
				.mj_img_list {
					width: calc(100%-15px);
					height: calc(100%-170px);
					padding: 5px;
					overflow: scroll;
				}
				.paginationContain{
					width: 100%;
					display: flex;
					justify-content: center; /* 水平居中 */
					
					.pagination {
						position: fixed;
						bottom: 0px;
					}
				}
				
			}
		}
	}
	
	.el-pagination .btn-next, .el-pagination .btn-prev {
		background: transparent;
		color: #c0c4cc;
	}	
	.el-pager li{
		background: transparent;
	}
	.el-pagination button:disabled{
		background: transparent;
	}
	.el-pager li.btn-quicknext, .el-pager li.btn-quickprev{
		color: #c0c4cc;
	}
	.el-pagination{
		color: unset;
	}
	
</style>