<template>
  <div>
	<indexHome />
  </div>
</template>

<script>
import indexHome from "@/components/VideoIndex.vue";

export default {
 components: {
   indexHome
 },
	data() {
		return {};
	},
	mounted() {},
	methods: {}
};
</script>
