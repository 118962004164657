<template>
  <div>
	<chatgpt :is_require_userLogin="true"/>
  </div>
</template>

<script>
import chatgpt from "@/components/ChatGpt.vue";
export default {
 components: {
   chatgpt
 },
	data() {
		return {
			
		};
	},
	mounted() {
	},
	methods: {
		
	}
};
</script>
