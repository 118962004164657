<template>
	<div>
		<el-popover
			placement="top"
			v-model="edit_collect_show"
			trigger="click">
			<div style="padding: 30px;border-radius: 10px;" :style="{color: appear_style==0?'#000':'aliceblue',backgroundColor: appear_style==0?'aliceblue':'#1f1f1f'}">
				<!-- <div style="display: flex;">
					<el-radio style="margin-top: 5px;margin-left: 0px;display: inline;" border size="medium" v-model="mj_collect_setting.search_range" @input="save_collect_setting" label="0">搜索当前收藏夹</el-radio>
					<el-radio style="margin-top: 5px;margin-left: 0px;display: inline;" border size="medium" v-model="mj_collect_setting.search_range" @input="save_collect_setting" label="1">搜索全部收藏夹</el-radio>
				</div>
				<div style="display: flex;padding: 5px;padding-top: 15px;">
					<el-checkbox v-model="mj_collect_setting.show_note" @change="handle_show_note_change">展示备注信息</el-checkbox>
				</div> -->
				<div style="width: 100%;display: grid;">
					<el-button style="width: 100%;margin: 10px;justify-self: center;" @click="newFloder" plain icon="el-icon-folder-opened">新建收藏夹</el-button>
				</div>
				<div></div>
				<div style="display: inline-flex;width: 100%;padding-top: 20px;">
					<div style="width: 70px;align-self: center;">收藏：</div>
					<div style="max-height: 150px;overflow-y: scroll;width: 100%;">
						<div v-for="(item, index) in mj_collect_list" >
							<el-checkbox style="font-size: 18px;width: 150px;overflow: hidden;" v-model="item.checked">{{item.title}}</el-checkbox>
						</div>
					</div>
				</div>
				<div></div>
				<div style="display: inline-flex;padding-top: 20px;">
					<div style="width: 70px;align-self: center;">备注：</div>
					<el-input v-model="mj_mes_item.notes" maxlength="30" @keydown.native="handleKeyDown" placeholder="请输入内容"></el-input>
				</div>
				<div style="text-align: right; margin-top: 20px">
				    <el-button size="mini" @click="edit_collect_show = false">取消</el-button>
				    <el-button type="primary" size="mini" @click="submitCollectEdit">确定</el-button>
				</div>
			</div>
			<el-button slot="reference" size="mini" icon="el-icon-folder-add" style="background-color: transparent;float: right;color: aliceblue;" @click="edit_collect_img($event)"></el-button>		
		</el-popover>
	</div>
</template>

<script>
import {addCollectMes,getMesInCollectList,compareCollectList} from "@/api/mj_api";

export default {
	props: {
		appear_style: Number,
		mj_mes_item: Object,
		colIndex: Number,
		curCollectId: String
	},
	data() {
		return {
			edit_collect_show:false,
			mj_collect_list:[],
			org_mj_collect_list:[],
			cur_mj_mes: this.mj_mes_item,
			col_index: this.colIndex,
			cur_collect_id: this.curCollectId
		}
	},
	watch: {
		edit_collect_show(newValue, oldValue) {
			// 在这里你可以添加更多逻辑
			if (newValue) {
				this.getMesInCollectList()
				// 如果 edit_collect_show 变为 true，执行某些操作
			} 
		}
	},
	methods:{
		compareCollectList(){
			var that=this
			compareCollectList(that.cur_mj_mes.mesId,that.cur_mj_mes.notes,that.org_mj_collect_list, that.mj_collect_list).then(response => {
				if(response.status==200){
					that.judgeSufDelete()
					this.$message({
						message: "修改成功！",
						type: 'success'
					});
				}
			})
		},
		judgeSufDelete(){
			var that=this
			if (that.cur_collect_id==="mj_list"){
				//执行表面添加
				that.$emit('editCollectSubmit',that.org_mj_collect_list, that.mj_collect_list);
			}else{
				//执行表面删除
				var item = that.mj_collect_list.find(function(element) {
				    return element.id === that.cur_collect_id;
				});
				if (item&&!item.checked){
					that.$emit('editCollectSubmit',that.col_index);
				}
			}
		},
		handleKeyDown(event) {
			if (event.key === "Enter" && event.shiftKey) {
			// 如果按下 Shift + Enter，则换行
				this.inputText += "\n";
			} else if (event.key === "Enter") {
			// 如果只按下 Enter，则执行提交逻辑
				event.preventDefault(); // 阻止默认的 Enter 换行行为
				this.submitCollectEdit()
			}
		},
		submitCollectEdit(){
			this.edit_collect_show = false
			if (this.mj_collect_list.length==0){
				this.$message({
					message: "您还没有创建收藏夹！",
					type: 'warning'
				});
				return
			}
			this.compareCollectList()
		},
		getMesInCollectList(){
			var that=this
			getMesInCollectList(localStorage.getItem("user_token"),that.cur_mj_mes.mesId).then(response => {
				if(response.status==200){
					that.mj_collect_list = response.res
					that.mj_collect_list.reverse();
					that.org_mj_collect_list = JSON.parse(JSON.stringify(that.mj_collect_list));
				}else{
					this.$message({
						message: response.res,
						type: 'warning'
					});
				}
			})
		},
		edit_collect_img(event){
			if(event!=null){
				event.stopPropagation();
			}
		},
		newFloder(){
			this.$emit('newFloder');
		}
	},
}
</script>

<style>
</style>