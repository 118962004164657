<template>
	<div>
	  <span v-for="i in 4" :style="{background:spanBackground}"></span>
	</div>
</template>

<script>
export default {
   props:['spanBackground'],
   data(){
    return {
		
    }
   }
}
</script>

<style scoped>
	div {
	  display: flex;
	  flex-flow: row nowrap;
	  align-items: center;
	  justify-content: space-between;
	  width: 1.7em;
	}
	
	span {
	  width: 0.3em;
	  height: 0.7em;
	  /* background-color: #aa55ff; */
	}
	
	span:nth-of-type(1) {
	  animation: grow 1s -0.45s ease-in-out infinite;
	}
	
	span:nth-of-type(2) {
	  animation: grow 1s -0.3s ease-in-out infinite;
	}
	
	span:nth-of-type(3) {
	  animation: grow 1s -0.15s ease-in-out infinite;
	}
	
	span:nth-of-type(4) {
	  animation: grow 1s ease-in-out infinite;
	}
	
	@keyframes grow {
	  0%,
	  100% {
	    transform: scaleY(1);
	  }
	
	  50% {
	    transform: scaleY(2);
	  }
	}

</style>