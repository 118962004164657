export function isDiscordLink(url) {
	const discordLinkPattern = /^https:\/\/cdn\.discordapp\.com/;
	return discordLinkPattern.test(url);
}

// js将如下字符串中的**pig fly2 --v 6.0** - Image #4，双**之间的内容提取出来，如pig fly2 --v 6.0,同时去除尖括号，如"<https://s.mj.run/NMxbaz4E2n8> pig"变为"https://s.mj.run/NMxbaz4E2n8 pig"
export function getMatchPrompt(prompt){
	const match = prompt.match(/\*\*(.*?)\*\*/);
	var res = ""
	if (match && match[1]) {
		const extractedContent = match[1];
		res = extractedContent
	}
	// 去除尖括号
	res = res.replace(/[<>]/g, '');
	return res
}
// 检查参数中是否有--zoom,如果没有就在最后添加--zoom 2,如果有就检查--zoom 后边的值是否在1和2之间，如果大于2就设置为2，小于1就设置为1，如果在1和2之间就保持不变
export function checkPromptZoom(prompt) {
    // Check if --zoom is in the prompt
    let zoomMatch = prompt.match(/--zoom\s+([0-9.]+)/);
    if (zoomMatch) {
        // Extract the zoom value
        let zoomValue = parseFloat(zoomMatch[1]);
        // Adjust the zoom value if necessary
        if (zoomValue > 2) {
            zoomValue = 2;
        } else if (zoomValue < 1) {
            zoomValue = 1;
        }
        // Replace the original zoom value in the prompt
        return prompt.replace(/--zoom\s+[0-9.]+/, `--zoom ${zoomValue}`);
    } else {
        // Append --zoom 2 to the prompt
        return `${prompt} --zoom 2`;
    }
}

checkPromptZoom("pig fly2 --v 6.0")
//检查和修改mj提示词
export function checkAndModifyPrompt(prompt){
	// js检查如上字符串，如果字符串有--前没有空格的，就在--前加个空格
	var pattern = /(?<!\s)--/g;
	var res = prompt.replace(pattern, ' --');
	
	// js检查是否有/imagine prompt:前缀，有就去掉
	pattern = /\/imagine prompt:/g;
	res = res.replace(pattern, '').trim();
	
	
	return res;
}
export function checkSingleUrl(text) {
    // 正则表达式匹配URL的模式
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    const matches = text.match(urlPattern); // 找到所有匹配的URL

    // 如果没有找到URL或者找到的URL数量不等于1，返回true
    if (!matches || matches.length !== 1) {
        return true;
    }

    // 如果只找到一个URL，且这个URL刚好是整个文本内容，返回false
    // 这里假设整个文本内容只包含这一个URL链接
    if (matches[0] === text.trim()) {
        return false;
    }

    // 如果找到一个URL但文本内容还包含其他文字或者多个URL链接，返回true
    return true;
}
// 遍历数组并处理每个元素
export function tranSuppleDescriptions(supple_descriptions){
	var result = supple_descriptions.map(description => {
		if (description.option_value !== "" && description.is_prefix==0) {
			let value = description.option_value;
			if (description.option_num !== 1) {
				value += `::${description.option_num}`;
			}
			return value;
		}
	}).filter(value => value !== undefined).join(',');
	return result;
}

export function tranOtherParams(other_params){
	var other_result = other_params.map(description => {
		if (description.option_value !== ""&&description.title == "其他描述") {
			let value = description.option_value;
			
			return value;
		}
	}).filter(value => value !== undefined).join(',');
	if (other_result!=null&&other_result!=''){
		other_result+=','
	}
	var link_result = other_params.map(description => {
		if (description.option_value !== ""&&description.title == "图片链接") {
			let value = description.option_value;
			return value;
		}
	}).filter(value => value !== undefined).join(' ');
	var result = link_result +' '+ other_result
	return result;
}

export function tranPrefixSuppleDescriptions(supple_descriptions){
	var result = supple_descriptions.map(description => {
		if (description.option_value !== "" && description.is_prefix==1) {
			let value = description.option_value;
			return value+" ";
		}
	}).filter(value => value !== undefined).join("");
	return result;
}

export function tranOfficialParams(official_params){
	var result = official_params.map(description => {
	  if (description.option_value !== "") {
	    let value = description.prefix+" "+ description.option_value;
	    return value;
	  }
	}).filter(value => value !== undefined).join(' ');
	return result;
}

export function imagineProcessPromptPro(text) {
    // 去除 --ar 及其参数
    let result = text.replace(/--\S+\s+\S*/g, '');

    // 去除带参数的URL
    result = result.replace(/https?:\/\/\S+\?\S+/g, '');
    // 去除不带参数的URL
    result = result.replace(/https?:\/\/\S+/g, '');
    // 去除 <https://s.mj.run/7HfUM5L_O1g> 部分
    result = result.replace(/<https?:\/\/\S+>/g, '');
    // 去除多余的空格和尖括号
    result = result.replace(/\s{2,}/g, ' ').trim();
    result = result.replace(/</g, '').trim();

    // 去除所有空格
    result = result.replace(/\s/g, '');

    // 去除特殊符号，只保留字母和数字
    result = result.replace(/[^a-zA-Z0-9]/g, '');

    return result;
}

export function matchPrompt(text) {
    const match = text.match(/\*\*(.*?)\*\*/);
    if (match) {
        return match[1];
    } else {
        return text;
    }
}
