import $ from 'jquery';
import {gpt_ip} from './environment.js'

export function getNotice(that) {
	$.ajax({
		url: gpt_ip+"/notice/getNotice",
		type: "GET"
	}).then(response => {
		if(response.status==200){
			if(response.res.notice_id!=localStorage.getItem("notice_id")){
				console.log('<vue-markdown source="'+response.res.notice_content+'" :key="item.content.length" v-highlight></vue-markdown>')
				that.$notify({
				  title: '通知：',
				  dangerouslyUseHTMLString: true,
				  duration: 0,
				  onClose: closeNotice(response.res.notice_id),
				  type: 'success',
				  message: '<p style="white-space: pre-wrap;">'+response.res.notice_content+'</p>' 
				});
			}
		}
		$(document).ready(function() {
			$('.el-notification').css('width', 'auto');
			$('.el-notification').css('min-width', '300px');
		});
	})
}

export function addChildListById(data, targetId, newElement) {
	for (let item of data) {
		if (item.id === targetId) {
			item.child = newElement;
			return true; // 返回true表示找到并添加了新元素
		}
		if (item.child && item.child.length > 0) {
			if (addChildListById(item.child, targetId, newElement)) {
				return true; // 递归调用，如果在子节点中找到则返回true
			}
		}
	}
	return false; // 返回false表示未找到指定id的元素
}
export function addChildItemById(data, targetId, newElement) {
	for (let item of data) {
		if (item.id === targetId) {
			if(item.child==null){
				item.child=[]
			}
			item.child.push(newElement);
			return true; // 返回true表示找到并添加了新元素
		}
		if (item.child && item.child.length > 0) {
			if (addChildItemById(item.child, targetId, newElement)) {
				return true; // 递归调用，如果在子节点中找到则返回true
			}
		}
	}
	return false; // 返回false表示未找到指定id的元素
}

export function findAndModifyOrDelete(list, targetId, newTitle = null) {
    for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (item.id === targetId) {
            if (newTitle !== null) {
                item.title = newTitle; // 修改 title
            } else {
                list.splice(i, 1); // 删除元素
            }
            return true; // 找到并操作成功
        }
        if (item.child && item.child.length > 0) {
            if (findAndModifyOrDelete(item.child, targetId, newTitle)) {
                return true; // 找到并操作成功
            }
        }
    }
    return false; // 未找到目标元素
}
// 判断传过来的参数是字符串还是List数组，如果是str就转成List
export function strToList(data){
	if (Array.isArray(data)) {
		return data;
	} else if (typeof data === 'string') {
		const urlPattern = /\[([^\]]+)\]/g;
		const matches = [];
		let match;
		
		// 查找所有匹配项
		while ((match = urlPattern.exec(data)) !== null) {
			matches.push(match[1]);
		}
		return matches;
	}
	return data;
}



function closeNotice(notice_id){
	localStorage.setItem("notice_id", notice_id);
	confirmNotice(notice_id)
}

function confirmNotice(notice_id) {
	return $.ajax({
		url: gpt_ip+"/notice/confirmNotice?notice_id="+notice_id,
		type: "GET"
	})
}

