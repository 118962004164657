<template>
	<div>
		<canvas id="repaintRange" class="repaintRange" width="300" height="50"></canvas>
	</div>
</template>

<script>
export default {
	data() {
		return {
			rectangles:[
				{ x: 100, y: 25, r: 3, color: 'white', isSelected: false }
			],
			rectexample:{ x: 100, y: 25, r: 3, color: 'white', isSelected: false },
			effect_r_add:2,
			effect_height: 30,
			primary_color: "white",
			active_color: "#55ff7f",
			max_color: "#ffaa00",
			min_color: "#5555ff",
			text_color: "white",
			line_color: "aqua",
			parent_width: 0
			
		};
	},
	mounted() {
		this.init()
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeCanvas);
	},
	methods: {
		init(){
			//窗口变化时贝塞尔曲线也会变化
			var canvas = document.getElementById('repaintRange');
			var parentWidth = canvas.parentElement.clientWidth;
			this.parent_width = parentWidth
			this.rectangles[0].x=parentWidth/2
			this.resizeCanvas();
			window.addEventListener('resize', this.resizeCanvas);
			
			this.lisClickCanvas()
			this.lisDbclickCanvas()
			this.rightClickCanvas()
		},
		update_repaint(y){
			this.rectangles.forEach(function(rectangle) {
				rectangle.y = y
			})
			this.draw()
		},
		lisDbclickCanvas(){
			var that = this
			var canvas = document.getElementById('repaintRange');
			canvas.addEventListener('dblclick', function(e) {
				
				var rect = canvas.getBoundingClientRect();
				var mouseX = e.clientX - rect.left;
				var rectexample = {...that.rectexample}
				if(mouseX>=0&&mouseX <=that.rectangles[0].x){
					rectexample.x = mouseX
					rectexample.y = that.rectangles[0].y
					that.rectangles.splice(0, 0, rectexample);
				}
				else if(mouseX >that.rectangles[that.rectangles.length-1].x){
					rectexample.x = mouseX
					rectexample.y = that.rectangles[that.rectangles.length-1].y
					that.rectangles.push(rectexample)
				}else{
					for (var i = 1; i < that.rectangles.length; i++) {
						if (
							mouseX >= that.rectangles[i-1].x &&
							mouseX <= that.rectangles[i].x 
							) {
							rectexample.x = mouseX
							rectexample.y = that.rectangles[i].y
							that.rectangles.splice(i, 0, rectexample);
							break;
						}
					}
				}
			
				that.draw()
			})
		},
		rightClickCanvas(){
			var that = this
			var canvas = document.getElementById('repaintRange');
			canvas.addEventListener('contextmenu', function(e) {
				// 阻止默认的右键菜单
				e.preventDefault();
			
				var rect = canvas.getBoundingClientRect();
				var mouseX = e.clientX - rect.left;
				var mouseY = e.clientY - rect.top;
				that.rectangles.forEach(function(rectangle,index) {
					if (
						mouseX >= rectangle.x - rectangle.r - that.effect_r_add &&
						mouseX <= rectangle.x + rectangle.r + that.effect_r_add &&
						mouseY >= rectangle.y - rectangle.r - that.effect_r_add &&
						mouseY <= rectangle.y + rectangle.r + that.effect_r_add &&
						that.rectangles.length>1
						) {
						that.rectangles.splice(index,1)
					}
				})
				that.draw()
			});
		},
		lisClickCanvas() {
			var that = this
			var canvas = document.getElementById('repaintRange');
			
			canvas.addEventListener('mousedown', function(e) {
				var rect = canvas.getBoundingClientRect();
				var mouseX = e.clientX - rect.left;
				var mouseY = e.clientY - rect.top;
				that.rectangles.forEach(function(rectangle) {
					if (
						mouseX >= rectangle.x - rectangle.r - that.effect_r_add &&
						mouseX <= rectangle.x + rectangle.r + that.effect_r_add &&
						mouseY >= rectangle.y - rectangle.r - that.effect_r_add &&
						mouseY <= rectangle.y + rectangle.r + that.effect_r_add 
						) {
						rectangle.isSelected = true
						rectangle.color=that.active_color
						document.addEventListener('mousemove', onMouseMove)
						document.addEventListener('mouseup', onMouseUp)
					}
				})
			})
			function onMouseMove(e) {
				var rect = canvas.getBoundingClientRect();
				var mouseX = e.clientX - rect.left;
				var mouseY = e.clientY - rect.top;
				
				e.preventDefault(); 
				that.rectangles.forEach(function(rectangle,index) {
					if(rectangle.isSelected){
						rectangle.x = mouseX;
						rectangle.y = mouseY;
						if (index!=that.rectangles.length-1){
							if(mouseX>=that.rectangles[index+1].x){
								rectangle.x = that.rectangles[index+1].x-1
							}
						}
						if (index!=0){
							if(mouseX<=that.rectangles[index-1].x){
								rectangle.x = that.rectangles[index-1].x+1
							}
						}
						if(rectangle.x<0){
							rectangle.x=0
						}else if(rectangle.x>that.parent_width){
							rectangle.x=that.parent_width
						}
						if (rectangle.y>canvas.height-(canvas.height-that.effect_height)/2){
							rectangle.y=canvas.height-(canvas.height-that.effect_height)/2
						}else if(rectangle.y<(canvas.height-that.effect_height)/2){
							rectangle.y=(canvas.height-that.effect_height)/2
						}
						that.draw()
					}
				})
			}
			
			function onMouseUp() {
				for (var i = 0; i < that.rectangles.length; i++) {
					if(that.rectangles[i].isSelected){
						that.rectangles[i].isSelected=false
						that.rectangles[i].color=that.primary_color
						that.draw()
						break;
					}
				}
				document.removeEventListener("mousemove", onMouseMove);
				document.removeEventListener("mouseup", onMouseUp);
			}
		},
		resizeCanvas() {
			var that = this
			var canvas = document.getElementById('repaintRange');
			var parentWidth = canvas.parentElement.clientWidth;
			canvas.width = parentWidth; // 设置Canvas的宽度为父元素的宽度
			that.rectangles.forEach(function(rec) {
				rec.x = parentWidth*(rec.x/that.parent_width)
			})
			this.draw();
			that.parent_width = parentWidth
		},
		draw(){
			var that = this
			var canvas = document.getElementById('repaintRange');
			if (!canvas.getContext) return;
			var ctx = canvas.getContext("2d");
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			ctx.beginPath();
			
			that.rectangles.forEach(function(rec,index) {
				ctx.strokeStyle = that.line_color;				
				if(index == 0){
					ctx.bezierCurveTo(0, rec.y, rec.x, rec.y, rec.x, rec.y);
				}else{
					var stro = (rec.x - that.rectangles[index-1].x)/2
					ctx.bezierCurveTo(that.rectangles[index-1].x + stro, that.rectangles[index-1].y, rec.x - stro, rec.y, rec.x, rec.y);
				}
				if(index == that.rectangles.length-1){
					ctx.bezierCurveTo(that.rectangles[index].x, that.rectangles[index].y, canvas.width, rec.y, canvas.width, rec.y);
				}
			})
			ctx.stroke();
			
			
			that.rectangles.forEach(function(rec) {
				ctx.beginPath();
				var value = (1-((rec.y-(canvas.height-that.effect_height)/2)/that.effect_height)).toFixed(2)
				if(value>0.9){
					ctx.fillStyle = that.max_color
				}else if(value<0.1){
					ctx.fillStyle = that.min_color
				}
				else{
					ctx.fillStyle = rec.color;
				}
				ctx.arc(rec.x, rec.y, rec.r, 0, 2*Math.PI);
				ctx.stroke();
				ctx.fill();
				ctx.beginPath();
				ctx.fillStyle = that.text_color; // 设置文字颜色
				ctx.fillText(value, rec.x+4, rec.y-2);
				ctx.stroke();
				ctx.fill();
			})
		}
	}
}
</script>

<style>
	.repaintRange{
		background-color: 'transparent';
	}
</style>