import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Highlight from "./utils/highlight-plugin"
// 引入vue-markdown
import VueMarkdown from "vue-markdown"
import VueAudio from 'vue-audio-better'
import {gpt_ip} from './api/environment.js'

Vue.use(VueAudio)
Vue.component("vue-markdown",  VueMarkdown)
Vue.use(Highlight);
Vue.use(ElementUI);

import App from './App.vue'
import router from './router'

Vue.prototype.config = {
	ip: gpt_ip,
	project_path: "/url/videos/",
	images_path: "/url/images/",
	ws:"ws://20.0.0.179:9091/websocket/"
}

Vue.config.productionTip = false

Vue.prototype.isMobile = function() {
	const userAgentInfo = navigator.userAgent;
	const mobileAgents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
	for (let i = 0; i < mobileAgents.length; i++) {
		if (userAgentInfo.indexOf(mobileAgents[i]) > 0) {
			return true; // 是移动设备
		}
	}
	return false; // 是PC设备
};

new Vue({
	el: '#app',
	router,
	render: h => h(App),
}).$mount('#app')

