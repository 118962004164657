<template>
	<div class="mj_full_screen_mask" v-show="mj_full_screen_img_url"  @click="handleMaskClick">
	    <img ref="full_image" id="full_image" class="mj_full_screen_img" @wheel="handleWheel" @mousedown="handleMouseDown" :src="mj_full_screen_img_url"/>
	    <div class="mj_full_screen_component">
			<div class="mj_full_screen_close"><el-button circle @click="handleMaskClick"><i class="el-icon-close"></i></el-button></div>
			<div class="mj_full_screen_restore"><el-button circle @click="restore_full_img($event)"><i class="el-icon-refresh"></i></el-button></div>
			<div class="mj_full_screen_download"><el-button circle @click="download_img($event)"><i class="el-icon-download"></i></el-button></div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			mj_full_screen_img_url: ''
		}
	},
	methods: {
		async download_img(event){
			if(event!=null){
				event.stopPropagation();
			}
			try {
				const fileName = this.mj_full_screen_img_url.split('/').pop(); // 提取 URL 最后的部分作为文件名

				// 使用 fetch 来获取图片的二进制数据
				const response = await fetch(this.mj_full_screen_img_url); // 替换为你的图片URL
				const blob = await response.blob();  // 将响应转换为 Blob 对象
				const url = window.URL.createObjectURL(blob);  // 创建指向 Blob 对象的 URL
				// 创建隐藏的 <a> 标签并触发下载
				const link = document.createElement('a');
				link.href = url;
				link.download = fileName;  // 设置文件名
				document.body.appendChild(link);
				link.click();  // 触发点击事件，强制下载
				document.body.removeChild(link);  // 移除临时的 <a> 元素
		
				// 释放 URL 对象
				window.URL.revokeObjectURL(url);
			} catch (error) {
				console.error('图片下载失败', error);
			}
		},
		handleWheel(event) {
			event.preventDefault();
			const delta = Math.sign(event.deltaY); // 获取滚轮滚动方向，1为向下，-1为向上
			const zoomSpeed = 0.5
		
			// 获取鼠标相对于图片左上角的位置
			const image = this.$refs.full_image;
			const rect = image.getBoundingClientRect();
		
			const mouseX = event.clientX - rect.left;
			const mouseY = event.clientY - rect.top;
			// 获取当前图片的缩放比例
			const scaleMatch = /scale\(([^)]+)\)/.exec(image.style.transform);
			const currentScale = scaleMatch ? parseFloat(scaleMatch[1]) : 1;
		
			// 计算新的缩放比例
			const newScale = delta === 1 ? currentScale - zoomSpeed : currentScale + zoomSpeed;
		
		
			// 确保缩放比例不会小于1（或其他最小值）
			const finalScale = Math.max(newScale, 0.3); 
			// finalScale(mouseX/currentScale-newX) + newX + image.offsetLeft = event.clientX
		
			var newX = (event.clientX - mouseX*finalScale/currentScale - image.offsetLeft)/(1-finalScale)
			var newY = (event.clientY - mouseY*finalScale/currentScale - image.offsetTop)/(1-finalScale)
			console.log(newX)
			console.log(newY)
			if (newX == Infinity||newX == -Infinity||Math.abs(newX)>100000){
				
				var transX = event.clientX - mouseX / currentScale - image.offsetLeft
				var transY = event.clientY - mouseY / currentScale - image.offsetTop
				
				image.style.transform = `translate(${transX}px,${transY}px) scale(${finalScale})`
				image.style.transformOrigin = `0px 0px`;
			}else{
				const transform = image.style.transform;
				const translateMatch = /translate\(([^px]+)px,\s*([^px]+)px\)/.exec(transform);
				
				var transformX = translateMatch ? parseFloat(translateMatch[1]) : 0;
				var transformY = translateMatch ? parseFloat(translateMatch[2]) : 0;
				newX = (event.clientX - mouseX*finalScale/currentScale - image.offsetLeft - transformX)/(1-finalScale)
				newY = (event.clientY - mouseY*finalScale/currentScale - image.offsetTop - transformY)/(1-finalScale)
				image.style.transform = `translate(${transformX}px,${transformY}px) scale(${finalScale})`;
				image.style.transformOrigin = `${newX}px ${newY}px`;
			}
		},
		handleMouseDown(event) {
			if (event.button !== 0) return; // 只响应左键
			event.preventDefault();
			this.isDragging = true;
			this.dragStartX = event.clientX;
			this.dragStartY = event.clientY;
		
			const image = this.$refs.full_image;
			const transform = image.style.transform;
			const translateMatch = /translate\(([^px]+)px,\s*([^px]+)px\)/.exec(transform);
			this.initialOffsetX = translateMatch ? parseFloat(translateMatch[1]) : 0;
			this.initialOffsetY = translateMatch ? parseFloat(translateMatch[2]) : 0;
		
			document.addEventListener('mousemove', this.handleMouseMove);
			document.addEventListener('mouseup', this.handleMouseUp);
		},
		handleMouseMove(event) {
			if (!this.isDragging) return;
		
			const dx = event.clientX - this.dragStartX;
			const dy = event.clientY - this.dragStartY;
		
			const image = this.$refs.full_image;
			const currentScale = parseFloat(image.style.transform.replace(/.*scale\(([^)]+)\).*/, '$1')) || 1;
		
			image.style.transform = `translate(${this.initialOffsetX + dx}px, ${this.initialOffsetY + dy}px) scale(${currentScale})`;
			this.clickPrevented = true;
		
		},
		handleMouseUp() {
			this.isDragging = false;
			document.removeEventListener('mousemove', this.handleMouseMove);
			document.removeEventListener('mouseup', this.handleMouseUp);
			setTimeout(() => {
				this.clickPrevented = false;
			}, 0);
		
		},
		restore_full_img(event){
			if(event!=null){
				event.stopPropagation();
			}
			const image = this.$refs.full_image;
			image.style.transformOrigin = ``;
			image.style.transform = ``;
		},
		click_mj_img(img_url){
			this.restore_full_img()
			this.mj_full_screen_img_url = img_url
		},
		handleMaskClick(event) {
			if (this.clickPrevented) {
				event.preventDefault();
				this.clickPrevented = false;
				return;
			}
			this.click_full_screen_img();
		},
		
		click_full_screen_img(){
			this.mj_full_screen_img_url=""
		},
	}
}
</script>

<style>
.mj_full_screen_mask{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #000;
	z-index: 201;
	
	.mj_full_screen_img{
		max-width: 100%;
		max-height: 100%;
		object-fit: contain; /* 确保图片按比例缩放 */
	}
	
	.mj_full_screen_component{
		position: absolute;
		right: 50px;
		font-size: large;
		z-index: 202;
		
		.mj_full_screen_close{
			
		}
		.mj_full_screen_restore{
			margin-top: 20px;
		}
		.mj_full_screen_download{
			margin-top: 20px;
		}
	}
}
</style>