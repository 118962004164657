import $ from 'jquery';
import {tts_ip} from './environment.js'

const ip = tts_ip



export function getCountryList() {
	return $.ajax({
		url: ip+"/voice/getCountryList",
		type: "GET"
	})
}

export function getLocalAndShortNameList(localeName,sex) {
	return $.ajax({
		url: ip+"/voice/getLocalAndShortNameList?localeName="+localeName+"&sex="+sex,
		type: "POST"
	})
}

export function getStyleAndRoleList(shortName) {
	return $.ajax({
		url: ip+"/voice/getStyleAndRoleList?shortName="+shortName,
		type: "POST"
	})
}

export function getVoiceUrl(name,type,role,text,userToken) {
	var input = {
		"name": name,
		"type": type,
		"role": role,
		"text": text
	}
	return $.ajax({
		url: ip+"/voice/getVoice?userToken="+userToken,
		type: "POST",
		dataType: "json", // 期望的响应数据类型
		contentType: "application/json", // 设置请求的内容类型为 JSON
		data: JSON.stringify(input)
	})
}

export function getListVoiceUrl(voiceRoleList,userToken) {
	return $.ajax({
		url: ip+"/voice/getListVoice?userToken="+userToken,
		type: "POST",
		dataType: "json", // 期望的响应数据类型
		contentType: "application/json", // 设置请求的内容类型为 JSON
		data: JSON.stringify(voiceRoleList)
	})
}

export function saveTtsList(mesListItems,userToken) {
	return $.ajax({
		url: ip+"/ttsListMes/saveTtsList?userToken="+userToken,
		type: "POST",
		dataType: "json", // 期望的响应数据类型
		contentType: "application/json", // 设置请求的内容类型为 JSON
		data: JSON.stringify(mesListItems)
	})
}

export function getTtsList(userToken) {
	return $.ajax({
		url: ip+"/ttsListMes/getTtsList?userToken="+userToken,
		type: "GET"
	})
}

export function delMesList(mesListId) {
	return $.ajax({
		url: ip+"/ttsListMes/delMesList?mesListId="+mesListId,
		type: "GET"
	})
}