<template>
  <div>
	<MJ />
  </div>
</template>

<script>
import MJ from "@/components/MJ.vue";
export default {
 components: {
   MJ
 },
	data() {
		return {};
	},
	mounted() {
	},
	methods: {
		
	}
};
</script>
