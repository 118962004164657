<template>
	<div class="cueword">
		<div class="custom_input" id="custom_input" contenteditable=true :placeholder="placeholder">
			<div>name</div>
			<div>input</div>
		</div>
		<div class="cue_show" id="cue_show">
			<div class="cue_item" v-for="(item, index) in cur_words" :index="index" :key="index" :style="{left: item.left+'%', right: (100-item.right)+'%'}">
				{{item.text}}
				<div class="cue_button" :style="{background: item.is_selected?active_color:item.input_selected?input_active_color:primary_color}" :index="index"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  data() {
	return {
		cur_words:[{left: 0, right: 100, text:"", is_selected: false, input_selected:false}],
		cur_words_exmp:{left: 0, right: 0, text:"", is_selected:false, input_selected:false},
		active_color:"#ffaa00",
		primary_color: "white",
		input_active_color: "aqua",
		placeholder:"请输入提示词……",
		last_inner_len:0,
		id_prefix:"txt"
	};
  },
  mounted() {
	this.init()
  },
  beforeDestroy() {
	var that = this
	document.removeEventListener('keydown', that.lisKeyDown);
  },
  methods: {
	init(){
		this.dblClickLis()
		this.movLis()
		this.clickLis()
		this.inputLis()
		this.clickItem()
		this.stopPropagation()
		this.keyDown()
	},
	keyDown(){
		var that = this
		document.addEventListener('keydown', that.lisKeyDown);
	},
	lisKeyDown(event) {
		var that = this
		if (event.keyCode === 37) {
		// 左箭头键的处理逻辑
			that.addEveKeyDown('left')
		} else if (event.keyCode === 39) {
		// 右箭头键的处理逻辑
			that.addEveKeyDown('right')
		}
	},
	addEveKeyDown(type){
		var that = this
		const video = document.getElementById('orgvideo');
		if(type == 'left'){
			video.currentTime-=0.01
		}else if(type == 'right'){
			video.currentTime+=0.01
		}
		var mouseX =100*(video.currentTime/video.duration)
		
		that.cur_words.forEach(function(item,index) {
			if(item.input_selected &&index<that.cur_words.length-1){
				item.right=mouseX
				if(index<that.cur_words.length-1){
					that.cur_words[index+1].left=mouseX
				}
			}
			if(item.left>item.right-2){
				item.right=item.left+2
				if(index<that.cur_words.length-1){
					that.cur_words[index+1].left=item.right
				}
			}
			if(index<that.cur_words.length-1){
				if(item.right>98){
					item.right=98
				}
				if(item.right>that.cur_words[index+1].right-2){
					item.right=that.cur_words[index+1].right-2
					that.cur_words[index+1].left=item.right
				}
			}
			if(that.cur_words[that.cur_words.length-1].right>100||that.cur_words[that.cur_words.length-1].left>98){
				that.cur_words[that.cur_words.length-1].left=98
				that.cur_words[that.cur_words.length-1].right=100
			}
		})
	},
	stopPropagation(){
		var custom_input = document.getElementById('custom_input');
		custom_input.addEventListener('click', function(event) {
			event.stopPropagation();
		})
		custom_input.addEventListener('mousedown', function(event) {
			event.stopPropagation();
		})
		custom_input.addEventListener("mousemove", function(event) {
			event.stopPropagation();
		});
	},
	innerClick(event) {
		event.stopPropagation();
		console.log('内层被点击！');
	},
	textToHtml(text){
		var parts = text.split(',');
		var html = ""
		for (var i = 0; i < parts.length; i++) {
			html+="<div id="+this.id_prefix+i+" class='custom_input_div'><div  contenteditable='false' class="+this.id_prefix+"t id="+this.id_prefix+i+"t><div id="+this.id_prefix+i+"tm class='txt0tm'>-</div><div id="+this.id_prefix+i+"tn class='txt0tn'>1</div><div id="+this.id_prefix+i+"ta class='txt0ta'>+</div></div><div class='txt0c' id="+this.id_prefix+i+"c>"+parts[i]+(i!=parts.length-1?",":"")+"</div></div>"
			// html+="<div id="+this.id_prefix+i+" class='custom_input_div'>"+parts[i]+(i!=parts.length-1?",":"")+"</div>"
		}
		return html
	},
	//隐藏上边的提示部分
	hideTishi(){
		var that=this
		try{
			const txtst = document.getElementsByClassName(that.id_prefix+'t');
			for (let i = 0; i < txtst.length; i++) {
				txtst[i].style.display = 'none';
			}
		}catch(e){
			console.log()
		}
		that.inputMousOverLis()
	},
	checkId(id){
		if(id.slice(-1,)=='c'||id.slice(-1,)=='t'){
			id=id.slice(0,-1)
		}
		if(id.slice(-2,)=='tm'||id.slice(-2,)=='tn'||id.slice(-2,)=='ta'){
			id=id.slice(0,-2)
		}
		return id;
	},
	updateText(id){
		const txt0tn = document.getElementById(id+'tn');
		const txt0c = document.getElementById(id+'c');
		const regExp = /^\(([\w\s\d]+):(\d+(\.\d+)?)\),$/;
		const isMatch = regExp.test(txt0c.innerText);
		let value = parseFloat(txt0tn.innerText);
		if (isMatch) {
			const matchResult = regExp.exec(txt0c.innerText);
			var custom = matchResult[1]; // 获取匹配到的字母部分的字符串
			// var matchedNumber = matchResult[2]; // 获取匹配到的数字部分的字符串
			txt0c.innerText="("+custom+":"+value.toFixed(1)+"),"
			
			if(txt0tn.innerText==1){
				txt0c.innerText = custom+","
			}
		}else{
			if(txt0c.innerText.slice(-1,)==','){
				txt0c.innerText="("+txt0c.innerText.slice(0,-1)+":"+value.toFixed(1)+"),"
				
			}else{
				txt0c.innerText="("+txt0c.innerText+":"+value.toFixed(1)+"),"
			}
		}
		
	},
	txt0tm(){
		var id=event.target.id
		id=this.checkId(id)
		// 修改txt0tn部分
		const txt0tn = document.getElementById(id+'tn');
		let value = parseFloat(txt0tn.innerText);
		if (value > 0) {
			value -= 0.1;
			txt0tn.innerText = value.toFixed(1);
		}
		// 修改txt0c部分
		this.updateText(id)
		
	},
	txt0ta(){
		var id=event.target.id
		id=this.checkId(id)
		
		const txt0tn = document.getElementById(id+'tn');
		let value = parseFloat(txt0tn.innerText);
		if (value < 10) {
			value += 0.1;
			txt0tn.innerText = value.toFixed(1);
		}
		
		// 修改txt0c部分
		this.updateText(id)
	},
	inputToTishi(id){
		const txt0tn = document.getElementById(id+'tn');
		const txt0c = document.getElementById(id+'c');
		const regExp = /^\(([\w\s\d]+):(\d+(\.\d+)?)\),$/;
		const isMatch = regExp.test(txt0c.innerText);
		if (isMatch) {
			const matchResult = regExp.exec(txt0c.innerText);
			
			txt0tn.innerText = parseFloat(matchResult[2]);
		}
	},
	txtsMouseOver(){
		var the=event.target
		var id=the.id
		id=this.checkId(id)
		var that=this
		document.getElementById(id).addEventListener('mouseleave', that.txtsMouseOut)
		
		const txtst = document.getElementById(id+'t');
		var customInput = document.getElementById('custom_input');
		
		customInput.setAttribute("contenteditable", false); 
		txtst.style.display='flex'
		
		that.inputToTishi(id)
		
		const txt0tm = document.getElementById(id+'tm');
		txt0tm.addEventListener('click',that.txt0tm)
		const txt0ta = document.getElementById(id+'ta');
		txt0ta.addEventListener('click',that.txt0ta)
		
	},
	txtsMouseOut(){
		var id=event.target.id
		id=this.checkId(id)
		
		var that=this
		const txtst = document.getElementById(id+'t');
		var customInput = document.getElementById('custom_input');
		
		const txt0tm = document.getElementById(id+'tm');
		txt0tm.removeEventListener('click',that.txt0tm)
		const txt0ta = document.getElementById(id+'ta');
		txt0ta.removeEventListener('click',that.txt0ta)
		
		
		customInput.setAttribute("contenteditable", true);
		txtst.style.display='none'
		
		document.getElementById('custom_input').innerHTML=this.textToHtml(document.getElementById('custom_input').innerText)
		
		that.hideTishi()
		
		Array.from(that.cur_words).forEach(function(cur_word) {
			if(cur_word.input_selected){
				cur_word.text =  document.getElementById('custom_input').innerText
			}
		});
		
		that.last_inner_len = document.getElementById('custom_input').innerText.split(',').length
	},
	inputMousOverLis(){
		var that=this
		var custom_input_divs = document.getElementsByClassName('custom_input_div');
		for (let i = 0; i < custom_input_divs.length; i++) {
			var id = custom_input_divs[i].id
			const txts = document.getElementById(id);
			txts.addEventListener('dblclick', that.txtsMouseOver)
		}
	},
	movInputMousOverLis(){
		var that=this
		var custom_input_divs = document.getElementsByClassName('custom_input_div');
		for (let i = 0; i < custom_input_divs.length; i++) {
			var id = custom_input_divs[i].id
			
			const txts = document.getElementById(id);
			txts.removeEventListener('dblclick', that.txtsMouseOver)
			txts.removeEventListener('mouseleave', that.txtsMouseOut)
		}
	},
	dblClickLis(){
		var that = this
		//移除监听,rightDown不要再移除了
		var cueButtons = document.getElementsByClassName('cue_button');
		Array.from(cueButtons).forEach(function(button) {
			button.removeEventListener('mousedown', that.mousedown);
		});
		var cue_items = document.getElementsByClassName('cue_item');
		Array.from(cue_items).forEach(function(cue_item) {
			cue_item.removeEventListener('click', that.clickItem);
		})
		
		var cue_show = document.getElementById('cue_show');
		cue_show.addEventListener("dblclick",function(e){
			e.preventDefault();
			for (var i = 0; i < that.cur_words.length; i++) {
				if(100*(e.clientX/cue_show.clientWidth)>that.cur_words[i].left &&
					100*(e.clientX/cue_show.clientWidth)<that.cur_words[i].right &&
					(that.cur_words[i].right-100*(e.clientX/cue_show.clientWidth))>2){
					var cur_words_item = {...that.cur_words_exmp}
					cur_words_item.left = 100*(e.clientX/cue_show.clientWidth)
					cur_words_item.right = that.cur_words[i].right
					cur_words_item.text = that.cur_words[i].text
					that.cur_words[i].right = cur_words_item.left
					that.cur_words.splice(i+1,0,cur_words_item)
					that.movLis()
					that.rightClickLis()
					that.clickLis()
					that.hideTishi()
					
					break;
				}
			}
		})
	},
	
	contextmenu(e){
		e.preventDefault();
		var that=this
		var index = e.target.getAttribute("index")
		var cur_word = that.cur_words[index]
		if(index>0){
			if(index<that.cur_words.length-1){
				that.cur_words[index-1].right=cur_word.right
			}else{
				that.cur_words[index-1].right=100
			}
			that.cur_words.splice(index,1)
		}
		for(var i=0;i<this.cur_words.length;i++){
			if(i==that.cur_words.length-1 && !that.cur_words[i].input_selected){
				that.cur_words[0].input_selected = true
				document.getElementById('custom_input').innerHTML = that.textToHtml(that.cur_words[0].text);
			}
		}
		that.hideTishi()
	},
	clickItem(e){
		var that=this
		for(var i=0;i<this.cur_words.length;i++){
			if(that.cur_words[i].input_selected){
				that.cur_words[i].input_selected = false
				
				var index = e.target.getAttribute("index")
				var cur_word = this.cur_words[index]
				cur_word.input_selected = true
				document.getElementById('custom_input').innerHTML = that.textToHtml(cur_word.text);
				break
			}
			if(i==that.cur_words.length-1 && !that.cur_words[i].input_selected){
				that.cur_words[0].input_selected = true
				document.getElementById('custom_input').innerHTML = that.cur_words[0].text==""?"":that.textToHtml(that.cur_words[0].text);
			}
		}
		that.hideTishi()
	},
	inputLisMethod(){
		var that = this
		var the = event.target
		var range = window.getSelection().getRangeAt(0); // 获取光标位置
		var startOffset = range.startOffset;
		var id = range.startContainer.parentElement.id
		var id1=id
		var parts = the.innerText.split(',');
		if(parts.length==that.last_inner_len+1){
			id = that.id_prefix+(parts.length-1)
		}
		if(id.slice(-1,)=='c'){
			id=id.slice(0,-1)
		}
		Array.from(that.cur_words).forEach(function(cur_word) {
			if(cur_word.input_selected){
				cur_word.text =  the.innerText.replace(/\n/g, "");
				the.innerHTML = that.textToHtml(the.innerText)
				
				//隐藏上边的提示部分
				try{
					const txtst = document.getElementsByClassName(that.id_prefix+'t');
					for (let i = 0; i < txtst.length; i++) {
						txtst[i].style.display = 'none';
					}
				}catch(e){
					console.log()
				}
			}
		});
		var id2=id
		try{
			var customInput1 = document.getElementById(id+'c');
			var range2 = window.getSelection(); //创建range
			range2.selectAllChildren(customInput1); //range 选择obj下所有子内容
			if(parts.length==that.last_inner_len+1 || id1==id2){
				range2.collapseToEnd()
			}else{
				range2.collapse(customInput1.childNodes[0], startOffset)
			}
		}catch(e){
			console.log()
		}
		
		that.last_inner_len =  the.innerText.split(',').length
		
		that.movInputMousOverLis()
		that.inputMousOverLis()
	},
	inputLis(){
		var that = this
		document.getElementById('custom_input').addEventListener('input', that.inputLisMethod);
	},
	clickLis(){
		var that = this
		var cue_items = document.getElementsByClassName('cue_item');
		if(cue_items.length == this.cur_words.length){
			Array.from(cue_items).forEach(function(cue_item) {
				cue_item.addEventListener('click', that.clickItem);
			});
		}else{
			setTimeout(()=>this.clickLis(),50)
		}
	},
	rightClickLis(){
		var that = this
		var cue_items = document.getElementsByClassName('cue_item');
		if(cue_items.length == this.cur_words.length){
			
			Array.from(cue_items).forEach(function(cue_item) {
				cue_item.addEventListener('contextmenu', that.contextmenu);
			});
		}else{
			setTimeout(()=>this.rightClickLis(),50)
		}
	},
	mousedown(e) {
		var that=this
		document.addEventListener('mousemove', that.onMouseMove)
		document.addEventListener('mouseup', that.onMouseUp)
		var cue_word = that.cur_words[e.target.getAttribute("index")]
		cue_word.is_selected = true
	},
	onMouseMove(e){
		e.preventDefault();
		var that=this
		var cue_show = document.getElementById('cue_show');
		var mouseX = 100*(e.clientX/cue_show.clientWidth)

		
		that.cur_words.forEach(function(item,index) {
			if(item.is_selected &&index<that.cur_words.length-1){
				item.right=mouseX
				if(index<that.cur_words.length-1){
					that.cur_words[index+1].left=mouseX
				}
			}
			if(item.left>item.right-2){
				item.right=item.left+2
				if(index<that.cur_words.length-1){
					that.cur_words[index+1].left=item.right
				}
			}
			if(index<that.cur_words.length-1){
				if(item.right>98){
					item.right=98
				}
				if(item.right>that.cur_words[index+1].right-2){
					item.right=that.cur_words[index+1].right-2
					that.cur_words[index+1].left=item.right
				}
			}
			if(that.cur_words[that.cur_words.length-1].right>100||that.cur_words[that.cur_words.length-1].left>98){
				that.cur_words[that.cur_words.length-1].left=98
				that.cur_words[that.cur_words.length-1].right=100
			}
		})
	},
	onMouseUp(){
		var that=this
		that.cur_words.forEach(function(item) {
			item.is_selected=false
		})
		document.removeEventListener('mousemove', that.onMouseMove)
		document.removeEventListener('mouseup', that.onMouseUp)
	},
	movLis(){
		var that = this
		var cueButtons = document.getElementsByClassName('cue_button');
		if(cueButtons.length == this.cur_words.length){
			Array.from(cueButtons).forEach(function(button) {
				button.addEventListener('mousedown', that.mousedown);
			});
		}else{
			setTimeout(()=>this.movLis(),50)
		}
	}
  }
  }
</script>

<style>
.custom_input:empty:before{
	content: attr(placeholder);
}

.cueword{
	width: 100%;
	min-height: 74px;
	/* background-color: aliceblue; */
	
	.custom_input{
		border: 2px solid #ccc; 
		padding: 6px; 
		border-radius: 5px; 
		font-size: 16px;
		color: aliceblue;
		margin: 5px;
		min-height: 30px;
		z-index: 30;
		
		.custom_input_div{
			color:aliceblue;
			border:2px solid #ccc;
			display:inline-block;
			padding: 3px;
			border-radius: 5px;
			text-align: center;
			
			.txtt{
				background:#aaaa7f;
				text-align: center;
				display:flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 3px;
				border-radius: 5px;
				padding: 2px;
				.txt0tm{
					width:25px;
					height: 15px;
					border:2px solid #ccc;
					border-radius: 5px;
				}
				.txt0tn{
					width:30px;
					height: 15px;
					display:inline;
					white-space: nowrap;
				}
				.txt0ta{
					width:25px;
					height: 15px;
					border:2px solid #ccc;
					border-radius: 5px;
				}
			}
			.txt0c{
				
			}
		}
	}
	
	.cue_show{
		height: 25px;
		.cue_item{
			position: absolute;
			padding-left: 15px;
			height: 22px;
			background-color: #13ce66;
			border-radius: 22px;
			overflow: hidden;
			
			.cue_button{
				width: 18px;
				height: 18px;
				position: absolute;
				right: 2px;
				background-color: aliceblue;
				border-radius: 18px;
				top: 2px;
			}
		}
	}
}
	
</style>