<template>
	<div class="mj_mes_type" :style="{color: appear_style==0?'#282828':'#bebebe'}">
		<div v-show="deep" :style="{width: (3-deep/1.2)+'px',height: '100%',backgroundColor:appear_style==0 ? '#aaaa7f' : '#62686d',position: 'absolute',left: '-7px'}"></div>
		<div v-for="(item, index) in mes_list" :key="index">
			<div class="mj_mes_item" @click="clickChatList(item)" :style="{boxShadow:cur_mes_list_id==item.id?'1px 1px aqua,-0.2px -0.2px aqua,0.1px 0.1px aqua inset':''}">
				<div class="mes_title" :style="{fontSize:(15-deep)+ 'px'}">
					<div class="mes_title_type" :style="{backgroundColor: appear_style==0 ? item.type=='0' ?'#00aaff': '#ffaa00' :item.type=='0' ?'#00aaff': '#00c25e'}" ></div>
					{{item.title}}
				</div>
				<div class="mes_tools">
					<i class="el-icon-view mes_tools_item" v-if="parseInt(item.isPublic)" ></i>
					<i class="el-icon-setting mes_tools_item" slot="reference" @click="clickSetting(item)" v-if="item.isModify"></i>
				</div>
			</div>
			<MJRecursiveComponent @clickChatList="clickChatList" @clickSetting="clickSetting" v-if="item.child!=null&&item.child.length>0" :appear_style="appear_style" :mes_list="item.child" :deep="deep+1" :cur_mes_list_id="cur_mes_list_id" :style="{marginLeft:'20px'}"/>
		</div>
	</div>
</template>

<script>
export default {
  name: "MJRecursiveComponent",
  props: {
    mes_list: Array,
	deep: Number,
	cur_mes_list_id: String,
	appear_style: Number,
  },
  methods: {
	clickChatList(chat_list_item){
		var a = [2]
		if(chat_list_item.child&&chat_list_item.child.length>0){
			chat_list_item.child = []
		}else{
			this.$emit('clickChatList', chat_list_item);
		}
	},
	clickSetting(chat_list_item){
		this.$emit('clickSetting',chat_list_item);
	}
  }
};

</script>
<style>
	.mj_mes_type{
		position: relative;
		border-radius: 5px;
		min-width: 70px;
		
		.mj_mes_item{
			padding-left: 10px;
			/* border-radius: 2px;
			box-shadow: 0.4px 0.2px #000, -0.4px -0.2px #aaaa7f; */
			margin-top: 5px;
			position: relative;
			height: 40px;
			line-height: 40px;
			
			.mes_title{
				width: 70%;
				padding-left: 2px;
				display: inline-block; /* 使用 inline-block 或 block */
				overflow: hidden;
				text-overflow: ellipsis; /* 显示省略号 */
				white-space: nowrap; /* 禁止换行 */
				height: 40px;
				line-height: 40px;
				
				
				.mes_title_type{
					width: 3px;
					height: 50%;
					position: absolute;
					top:22%;
					left: 3px;
					border-radius: 2px;
				}
			}
			.mes_tools{
				width: 30%;
				display: inline;
				position: absolute;
				right: 10px;
				
				.mes_tools_item{
					line-height: 40px;
					font-size: 16px;
					font-weight: 900;
					float: right;
					margin-left: 6px;
				}
			}
		}
	}
</style>
