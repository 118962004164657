import $ from 'jquery';
import {gpt_ip} from './environment.js'

export function getUserProfile(userToken) {
	return $.ajax({
		url: gpt_ip+"/user/getUserProfile?userToken="+userToken,
		type: "GET"
	})
}

export function modifyNick(userToken,nickname) {
	return $.ajax({
		url: gpt_ip+"/user/modifyNick?userToken="+userToken+"&nickname="+nickname,
		type: "GET"
	})
}

export function feedbackSendMes(userToken,role,content) {
	return $.ajax({
		url: gpt_ip+"/feedback/sendMes?user_token="+userToken+"&role="+role+"&content="+content,
		type: "GET"
	})
}

export function feedbackGetMes(userToken) {
	return $.ajax({
		url: gpt_ip+"/feedback/getMes?user_token="+userToken,
		type: "GET"
	})
}


