<template>
	<div class="mj_mes_type">
		<div class="mj_mes_type_item" v-for="(item, index) in mes_item_list" :key="index">
			<div class="mj_mes_type_avatar">
				<img class="mj_user_type_avatar" :src="(item.role==0)?require('../../assets/user_logo.jpg'):require('../../assets/assistant.png')"/>
			</div>
			<div class="mj_mes_type_name">{{item.role==0? item.username:"Midjourney"}}</div>
			<div class="mj_mes_type_cont" :style="{backgroundColor: appear_style==0 ? '#e3e3e3':'#1e1e1e'}">
				<div class="mj_mes_option_cont" style="display: inline-flex;">{{item.mesOption}}</div>
				<vue-markdown :source="item.mes" v-if="item.mes" :key="item.mes.length" v-highlight>
				</vue-markdown>
				<div class="mj_mes_cont_imgs" v-for="(item2, index2) in vueStrToList((item.thumbnailUrls==null||item.thumbnailUrls.length==0)?item.imgUrls:item.thumbnailUrls)" :key="index2" @mouseover="showCopyUrl(index+'_'+index2)" @mouseleave="hideCopyUrl">
					<div class="mj_mes_type_cont_div">
						<img class="mj_mes_type_cont_img" :src="item2" @click="click_mj_img(item.imgUrls[index2])"/>
						
						<!-- <div class="copy_mes_img_url" v-show="visibleIndex==(index+'_'+index2)"  @click="copyUrl(vueStrToList(item.discordUrls)[index2])">复制discord url</div> -->
										
						<div v-show="visibleIndex==(index+'_'+index2)||is_mobile" class="copy_mes_img_url">
							<el-button type="warning" size="mini" plain icon="el-icon-document-copy" style="background-color: transparent;float: right;color: aliceblue;" @click="copyUrl(vueStrToList(item.discordUrls)[index2])">复制discord url</el-button>
							<EditCollectInfo :curCollectId="'mj_list'" :appear_style="appear_style" :mj_mes_item="item" @newFloder="newFloder" @editCollectSubmit="editCollectSubmit"></EditCollectInfo>
						</div>
					</div>
					
					<div class="mj_mes_options_list" v-if="item.role!=0">
						<div class="mj_mes_options_line" v-for="(item3, index3) in getFilteredMesOptionsList(item.components)">
							<el-button size="mini" class="mj_mes_options_item" v-show="item4.label!='Web'&& item4.custom_id&&!item4.custom_id.startsWith('MJ::BOOKMARK')" v-for="(item4, index4) in item3.components" @click="vueClickMes(item2,item,item4)" :key="index4">
								<div style="position: relative;width: 100%;" >
									<div>{{item4.label ? item4.label : ''+(item4.emoji ? item4.emoji.name : '')}}</div>
									<!-- v-show="cur_mj_mes.component_label==item4.label && cur_mj_mes.mes_id==item.mesId &&cur_mj_mes.custom_id==item4.custom_id && is_sending_mes"-->
									<div v-show="cur_mj_mes.component_label==item4.label && cur_mj_mes.mes_id==item.mesId &&cur_mj_mes.custom_id==item4.custom_id && is_sending_mes" style="width: 100%;height: 100%;position: absolute;top: 0;background: #ecf5ff;">
										<load1 style="height: 15px;margin: 0px auto;" :spanBackground='appear_style==0?"#aaaaff":"aqua"' ></load1>
									</div>
								</div>
							</el-button>
						</div>
						<div class="mj_mes_custom_func">
							<el-button size="mini" class="mj_mes_options_item" v-for="(item4, index4) in mj_mes_custom_func_list" v-show="(item.mesOption=='imagine'||item.mesOption=='click_mes')&&item.role==1&&vueImagineProcessPromptPro(item.mes)" @click="vueAddReaction(item,item4)" :key="index4">
								<div style="position: relative;width: 100%;" >
									<div>{{item4.label}}</div>
									<!-- v-show="cur_mj_mes.component_label==item4.label && cur_mj_mes.mes_id==item.mesId &&cur_mj_mes.custom_id==item4.custom_id && is_sending_mes"-->
									<div v-show="cur_mj_mes.component_label==item4.label && cur_mj_mes.mes_id==item.mesId &&cur_mj_mes.custom_id==item4.custom_id && is_sending_mes" style="width: 100%;height: 100%;position: absolute;top: 0;background: #ecf5ff;">
										<load1 style="height: 15px;margin: 0px auto;" :spanBackground='appear_style==0?"#aaaaff":"aqua"' ></load1>
									</div>
								</div>
							</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="mj_full_screen_mask" v-show="mj_full_screen_img_url" @click="handleMaskClick">
		    <img ref="full_image" id="full_image" class="mj_full_screen_img" @wheel="handleWheel" @mousedown="handleMouseDown" :src="mj_full_screen_img_url"/>
		    <div class="mj_full_screen_restore"><el-button circle @click="restore_full_img($event)"><i class="el-icon-refresh"></i></el-button></div>
		</div> -->
		<PictureZoom ref="pictureZoom"></PictureZoom>
		<NewFavorites ref="newFavorites" @newFavoriteSuc="newFavoriteSuc"></NewFavorites>
	</div>
</template>

<script>
import {strToList} from "@/api/common_api";
import {clickMes, getSeed} from "@/api/mj_api";
import load1 from "@/components/icon/load1.vue";
import PictureZoom from "@/components/PictureZoom.vue";
import {imagineProcessPromptPro, matchPrompt} from "../../utils/mj_tools.js"
import EditCollectInfo from "@/components/EditCollectInfo.vue";
import NewFavorites from "@/components/NewFavorites.vue";

export default {
	components: {
	  load1,
	  PictureZoom,
	  EditCollectInfo,
	  NewFavorites,
	},
	name: "mj_mes_type",
	props: {
		mes_item_list: Array,
		mj_mes_options_list: Array,
		appear_style: Number,
		curCollectId: String
	},
	data() {
		return {
			is_mobile: this.isMobile(),
			mj_mes_custom_func_list: [{
				label: "Get Seed",
				custom_id: "get_seed"
			}],
			visibleIndex: null,
			mj_full_screen_img_url: "",
			is_sending_mes: false,
			cur_mj_mes:{
				component_label: "",
				mes_id: "",
				custom_id: ""
			},
			isDragging: false,
			dragStartX: 0,
			dragStartY: 0,
			initialOffsetX: 0,
			initialOffsetY: 0,
			clickPrevented: false

		};
	},
	
	methods: {
		newFavoriteSuc(){
			this.$emit('newFavoriteSuc');
		},
		editCollectSubmit(org_mj_collect_list, mj_collect_list){
			this.$emit('editCollectSubmit', org_mj_collect_list, mj_collect_list);
		},
		// 关键词不为空的才能用Get Seed方法
		newFloder(){
			this.$refs.newFavorites.newFavoriteShow=true
		},
		vueImagineProcessPromptPro(prompt) {
			return imagineProcessPromptPro(matchPrompt(prompt));
		},
		
		vueAddReaction(mes_item, custom_func_item){
			if (custom_func_item.custom_id == "get_seed"){
				this.vueGetSeed(mes_item, custom_func_item)
			}
		},
		vueGetSeed(mes_item, custom_func_item){
			if(this.is_sending_mes){
				this.$message({
					message: "消息发送中！",
					type: 'warning'
				});
				return
			}
			
			this.is_sending_mes = true
			this.cur_mj_mes.component_label = custom_func_item.label
			this.cur_mj_mes.custom_id = custom_func_item.custom_id
			this.cur_mj_mes.mes_id = mes_item.mesId
			
			getSeed(localStorage.getItem("user_token"), mes_item.mesId).then(response => {
				if(response.status==200){
					console.log(response.res)
					this.$message({
						message: "提交成功，请稍等片刻 (≧◡≦) ♡",
						type: 'success'
					});
					this.is_sending_mes = false
				}else{
					this.$message({
						message: response.res,
						type: 'warning'
					});
					this.is_sending_mes = false
				}
			})
		},
		
		click_mj_img(img_url){
			this.$refs.pictureZoom.click_mj_img(img_url)
		},
		
		copyUrl(url) {
			const input = document.createElement('input');
			input.setAttribute('value', url);
			document.body.appendChild(input);
			input.select();
			document.execCommand('copy');
			document.body.removeChild(input);
			this.$message({
				message: "复制成功！",
				type: 'success'
			});
		},
		vueClickMes(img_url,mes_item,component){
			if(this.is_sending_mes){
				this.$message({
					message: "消息发送中！",
					type: 'warning'
				});
				return
			}
			this.is_sending_mes = true
			this.cur_mj_mes.component_label = component.label
			this.cur_mj_mes.custom_id = component.custom_id
			this.cur_mj_mes.mes_id = mes_item.mesId
			clickMes(localStorage.getItem("user_token"), mes_item.mesId, component.type, component.custom_id).then(response => {
				if(response.status==200){
					console.log(response.res)
					if (component.label != "Vary (Region)"&&component.label != "Custom Zoom"){
						this.$message({
							message: "提交成功，请稍等片刻 (≧◡≦) ♡",
							type: 'success'
						});
					}
					this.$emit('clickMes',img_url, mes_item, component)
					this.is_sending_mes = false
				}else{
					this.$message({
						message: response.res,
						type: 'warning'
					});
					this.is_sending_mes = false
				}
			})
		},
		vueStrToList(str){
			return strToList(str)
		},
		getFilteredMesOptionsList(components) {
			if(components!=null&&components!=''){
				try{
					return JSON.parse(components)
				}catch{
					return null
				}
			}
			return null
		},
		showCopyUrl(index) {
			this.visibleIndex = index;
		},
		hideCopyUrl() {
			this.visibleIndex = null;
		},
	}
};
</script>

<style>
.mj_mes_type{
	.mj_mes_type_item{
		display: grid;
		grid-template-columns: 50px auto;
		grid-template-rows: 50px auto;
		grid-template-areas: 'a b' 
							'c d';
							
		.mj_mes_type_avatar{
			grid-area: a;
			display: flex;
			width: 40px;
			height: 100%;
			
			.mj_user_type_avatar{
				width: 40px;
				height: 40px;
				border-radius: 20px;
			}
		}
		
		.mj_mes_type_name{
			grid-area: b;
			font-weight: 700;
			padding: 10px;
			padding-top: 5px;
			border-radius: 5px;
			align-self: center;
			user-select: none;
		}
		
		.mj_mes_type_cont{
			overflow: auto;
			grid-area: d;
			border-radius: 5px;
			padding: 30px;
			/* border-left: 0.1em solid #737bb4; */
			min-width: 100%;
			
			.mj_mes_option_cont{
				display: inline-flex;
				font-size: 16px;
				font-weight: 800;
			}
			.mj_mes_text_cont{
				display: inline-flex;
				margin-left: 10px;
			}
			
			.mj_mes_cont_imgs{
				margin-top: 10px;
				position: relative;
				text-align: center;
				overflow: scroll;
				
				.mj_mes_type_cont_div{
					position: relative;
					
					.mj_mes_type_cont_img{
						border-radius: 10px;
						max-height: 640px;
						width: 100%; /* 保证宽度为100% */
						height: auto; /* 按比例调整高度 */
						display: block;
						margin: 0 auto;
						object-fit: contain; /* 保持图片不失真，并按比例缩放 */
					}
				}
			
				.mj_mes_acount{
					width: 10px;
					height: 10px;
					line-height: 10px;
					text-align: center;
					position: absolute;
					top: 0px;
					left: 0px;
					padding: 4px;
					border-radius: 50%;
					font-weight: 550;
					font-size: 13px;
					color: aliceblue;
					background-color: #a3a3a3;
				}
				
				.copy_mes_img_url{
					position: absolute;
					bottom: 0px;
					right: 0px;
					padding: 5px;
					margin: 2px;
					border-radius: 5px;
					font-weight: 550;
					font-size: 13px;
					cursor: pointer; /* 使光标变成手指 */
					display: inline-flex;
				}
				
				.mj_mes_options_list{
					padding: 10px 0px 10px 0px;
					width: 100%;
					display: flex;
					flex-wrap: wrap; /* 如果你希望在容器宽度不足时自动换行 */
					gap: 0px; /* 添加间距 */
					justify-content: center;
					
					.mj_mes_options_line{
						
						.mj_mes_options_item{
							font-weight: 550;
							font-size: 13px;
							margin-top: 10px;
						}
					}
					
					.mj_mes_custom_func{
						
						.mj_mes_options_item{
							font-weight: 550;
							font-size: 13px;
							margin-top: 10px;
						}
					}
				}
			}
		}
	}
}
</style>