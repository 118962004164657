import {query_project,get_video_info} from "@/api/video_redraw_api";

export function extractQuery(that) {
	if(!that.lock){
		that.lock = true
		clearInterval(that.intervalExtractQuery);
		that.intervalExtractQuery = setInterval(() => extractQuery_one(that), 1000);
	}
}
export function previewOneQuery(that) {
	if(!that.lock){
		that.lock = true
		clearInterval(that.intervalPreviewOneQuery);
		that.intervalPreviewOneQuery = setInterval(() => previewOneQuery_one(that), 1000);
	}
}
// "process"表示全部渲染的当前帧0、30、60……
export function previewAllQuery(that) {
	if(!that.lock){
		that.lock = true
		clearInterval(that.intervalPreviewAllQuery);
		that.intervalPreviewAllQuery = setInterval(() => previewAllQuery_one(that), 2000);
	}
}
// "process"0表示rend_all完毕，1表示ebsynth完毕
export function renderAllQuery(that) {
	if(!that.lock){
		that.lock = true
		clearInterval(that.intervalRenderAllQuery);
		that.intervalRenderAllQuery = setInterval(() => renderAllQuery_one(that), 5000);
	}
}
export function blendQuery(that) {
	if(!that.lock){
		that.lock = true
		clearInterval(that.intervalBlendQuery);
		that.intervalBlendQuery = setInterval(() => blend_one(that), 5000);
	}
}

export function clearAllLisAndInterval(that){
	clearInterval(that.intervalExtractQuery);
	clearInterval(that.intervalPreviewOneQuery);
	clearInterval(that.intervalPreviewAllQuery);
	clearInterval(that.intervalRenderAllQuery);
	clearInterval(that.intervalBlendQuery);
}

function blend_one(that) {
	query_project(that.project_name).then(response => {
		if(response.status==200){
			var res = JSON.parse(response.res)
			if(res.step=="blend"&&res.status=="complete"){
				var timestamp = Date.now();
				const video2 = document.getElementById('resVideo2');
				video2.src = that.config.ip+that.config.project_path+that.project_name+"/result/video.mp4?timestamp="+timestamp
				that.resVideo2Show = true
				that.$message({
					message: '视频渲染完毕！',
					type: 'success'
				});
				that.lock = false
				clearInterval(that.intervalBlendQuery);
			}else if(res.step=="blend"&&res.status=="cancel"){
				that.$message({
					message: '任务已取消！',
					type: 'success'
				});
				that.lock = false
				clearInterval(that.intervalBlendQuery);
			}
			getProjectStatus(res,that)
		}
	})
}

function renderAllQuery_one(that) {
	query_project(that.project_name).then(response => {
		if(response.status==200){
			var res = JSON.parse(response.res)
			if(res.step=="ebsynth"&&res.status=="complete"){
				var timestamp = Date.now();
				const video1 = document.getElementById('resVideo1');
				video1.src = that.config.ip+that.config.project_path+that.project_name+"/output/video.mp4?timestamp="+timestamp
				that.resVideo1Show = true
				that.$message({
					message: '视频渲染完毕！',
					type: 'success'
				});
				that.lock = false
				that.blend_lock = false
				clearInterval(that.intervalRenderAllQuery);
			}else if(res.step=="ebsynth"&&res.status=="cancel"){
				that.$message({
					message: '任务已取消！',
					type: 'success'
				});
				that.lock = false
				clearInterval(that.intervalRenderAllQuery);
			}else if(res.step=="render_all"&&res.status=="cancel"){
				that.$message({
					message: '任务已取消！',
					type: 'success'
				});
				that.lock = false
				clearInterval(that.intervalRenderAllQuery);
			}
			getProjectStatus(res,that)
		}
	})
}

function previewAllQuery_one(that) {
	const video = document.getElementById('orgvideo');
	
	query_project(that.project_name).then(response => {
		if(response.status==200){
			var res = JSON.parse(response.res)
			if(res.step=="preview_all" && (res.status=="render" || res.status=="complete")&& res.process!=that.last_process){
				var timestamp = Date.now();
				var current = Math.round(res.process/that.fps)
				
				that.preview_images[current] = that.config.ip+that.config.project_path+that.project_name+"/preview_output/"+res.process+".png?timestamp="+timestamp
				that.last_process = res.process
				video.currentTime = current
			}
			if(res.step=="preview_all" && res.status=="complete"){
				that.$message({
					message: '预览全部渲染完毕！',
					type: 'success'
				});
				that.last_process = -1
				that.lock = false
				clearInterval(that.intervalPreviewAllQuery);
			}else if(res.step=="preview_all"&&res.status=="cancel"){
				that.$message({
					message: '任务已取消！',
					type: 'success'
				});
				that.lock = false
				clearInterval(that.intervalPreviewAllQuery);
			}
			getProjectStatus(res,that)
		}
	})
}

function extractQuery_one(that) {
	query_project(that.project_name).then(response => {
		if(response.status==200){
			var res = JSON.parse(response.res)
			if(res.step=="extract"&&res.status=="complete"){
				
				that.$message({
					message: '预处理完毕！',
					type: 'success'
				});
				that.lock = false
				clearInterval(that.intervalExtractQuery);
				get_video_info(that.project_name).then(res => {
					that.fps = Math.round(JSON.parse(res.res).fps)
				})
			}else if(res.step=="extract"&&res.status=="cancel"){
				that.$message({
					message: '任务已取消！',
					type: 'success'
				});
				that.lock = false
				clearInterval(that.intervalExtractQuery);
			}
			getProjectStatus(res,that)
		}
	})
}

function previewOneQuery_one(that) {
	const video = document.getElementById('orgvideo');
	query_project(that.project_name).then(response => {
		if(response.status==200){
			var res = JSON.parse(response.res)
			if(res.step=="preview_one"&&res.status=="complete"){
				var timestamp = Date.now(); // 使用当前时间戳作为查询参数
				that.preview_images[that.currentTime] = that.config.ip+that.config.project_path+that.project_name+"/preview_output/"+that.currentTime*that.fps+".png?timestamp="+timestamp
				that.$message({
					message: '渲染完成！',
					type: 'success'
				});
				video.currentTime = that.currentTime
				that.lock = false
				clearInterval(that.intervalPreviewOneQuery);
			}else if(res.step=="preview_one"&&res.status=="cancel"){
				that.$message({
					message: '任务已取消！',
					type: 'success'
				});
				that.lock = false
				clearInterval(that.intervalPreviewOneQuery);
			}
			getProjectStatus(res,that)
		}
	})
}
function getProjectStatus(res,that){
	// 什么时候显示取消按钮
	if(res.status=="queue"){
		that.cancelShow = true
	}else{
		that.cancelShow = false
	}
	
	// 显示当前项目的状态
	that.status.step = res.step
	that.status.status = res.status
	if(res.status=="queue"){
		that.status.process = res.process+1
	}else if(res.status=="render"){
		
		if(res.step=="extract"){
			that.status.process = 0
		}else if(res.step=="preview_one"){
			that.status.process = 0
		}else if(res.step=="preview_all"){
			if(res.process!=-1){
				that.status.process = Math.floor((res.process/that.fps+1)/(Math.floor(that.org_video_dur)+1)*100)
			}else{
				that.status.process = 0
			}
		}else if(res.step=="render_all"){
			that.status.process = 0
		}else if(res.step=="ebsynth"){
			that.status.process = 50
		}else if(res.step=="blend"){
			that.status.process = 0
		}
	}
}